define("apollo/pods/components/partnership-table/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A9KSTLMZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"data-field-name\",\"name\"],[9],[1,[23,[\"companyFromPartnership\",\"name\"]],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"data-field-name\",\"shortname\"],[9],[1,[23,[\"companyFromPartnership\",\"shortName\"]],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"data-field-name\",\"tax-id-number\"],[9],[1,[23,[\"companyFromPartnership\",\"taxIdNumber\"]],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"data-field-name\",\"iban-symbol\"],[9],[1,[23,[\"companyFromPartnership\",\"country\",\"ibanSymbol\"]],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"data-field-name\",\"date-created\"],[9],[1,[27,\"my-format-date\",[[23,[\"partnership\",\"dateCreated\"]],\"defaultDateTime\"],null],false],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"canDeletePartnership\"]]],null,{\"statements\":[[0,\"    \"],[7,\"td\"],[12,\"data-field-name\",[27,\"concat\",[\"delete-company-\",[23,[\"companyFromPartnership\",\"shortName\"]]],null]],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"data-button-name\",\"delete\"],[11,\"class\",\"btn btn-default\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"common.delete\"],null],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"delete\",[23,[\"partnership\"]]]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/partnership-table/row/template.hbs"
    }
  });

  _exports.default = _default;
});