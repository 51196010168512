define("apollo/pods/checkpoint/model", ["exports", "ember-data", "apollo/mixins/model-validator", "ember-data/model"], function (_exports, _emberData, _modelValidator, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    dateFormatterService: Ember.inject.service('date.date-formatter-service'),
    transport: belongsTo('transport', {
      async: true,
      inverse: 'checkpoints'
    }),
    step: belongsTo('step', {
      async: true
    }),
    task: belongsTo('task', {
      async: true
    }),
    confirmator: belongsTo('user', {
      async: true
    }),
    expectedDate: _emberData.default.attr('date'),
    alertDate: _emberData.default.attr('date'),
    executionDate: _emberData.default.attr('date'),
    rejectionDate: _emberData.default.attr('date'),
    idx: _emberData.default.attr('number'),
    checkpointName: _emberData.default.attr('string'),
    blockadeReason: attr('string'),
    isBlocked: attr('boolean'),
    blockadeRequester: belongsTo('user', {
      async: true
    }),
    skipGeneratingSubtasks: attr('boolean'),
    ACTIONS: Object.freeze({
      CONFIRM: 'CONFIRM',
      REJECT: 'REJECT',
      BLOCK: 'BLOCK'
    }),
    validations: {
      executionDate: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return true;
            }

            const previousCheckpoint = model.get('transport.sortedCheckpoints').reverse().find(ch => {
              return ch.get('executionDate') && ch.get('id') !== model.get('id');
            });
            const isDateLaterThanPreviousCheckpoint = value > previousCheckpoint.get('executionDate');
            const isDateInPast = value < new Date();
            return isDateInPast && isDateLaterThanPreviousCheckpoint;
          },
          message: 'isInPast'
        }, {
          validation: function (key, value, model) {
            if (model.get('task.userHasToAddCheckpointConfirmationDate') && !value) {
              return false;
            }

            return true;
          },
          message: 'blank'
        }]
      },
      blockadeReason: {
        custom: [{
          validation: function (key, value, model) {
            if (!model.get('task.canBeBlocked')) {
              return true;
            }

            if (!model.get('blockadeRequester.id')) {
              return true;
            }

            return value;
          },
          message: 'blank'
        }, {
          validation: function (key, value) {
            if (!value) {
              return true;
            }

            return value.length < 255;
          },
          message: 'length'
        }]
      }
    },
    isLate: Ember.computed('executionDate', 'expectedDate', function () {
      return !this.get('isConfirmed') && this.get('expectedDate').getTime() <= new Date().getTime();
    }),
    wasLate: Ember.computed('executionDate', 'expectedDate', function () {
      if (this.get('executionDate') === null) {
        return false;
      } else {
        return this.get('expectedDate') < this.get('executionDate');
      }
    }),
    isConfirmed: Ember.computed('executionDate', 'confirmator.id', function () {
      return this.get('executionDate') !== null && this.get('confirmator.id');
    }),
    isRejected: Ember.computed('rejectionDate', function () {
      return this.get('rejectionDate') !== null;
    }),
    name: Ember.computed('task.{checkpointName,checkpointNameEn}', function () {
      const propertyName = this.get('languageService').findProperLanguageVersion('checkpointName', 'checkpointNameEn');
      return this.get('task').get(propertyName);
    }),
    altCheckpointName: Ember.computed('task.{altCheckpointName,altCheckpointNameEn}', function () {
      const propertyName = this.get('languageService').findProperLanguageVersion('altCheckpointName', 'altCheckpointNameEn');
      return this.get('task').get(propertyName);
    }),
    altCheckpointColor: Ember.computed('task.altCheckpointColor', function () {
      if (this.get('task.altCheckpointColor')) {
        return Ember.String.htmlSafe('background-color: ' + this.get('task.altCheckpointColor') + ';');
      }

      return null;
    }),
    altCheckpointLabel: Ember.computed('name', 'altCheckpointName', function () {
      const altCheckpointName = this.get('altCheckpointName');
      const checkpointName = this.get('name');
      const taskIncludesWeighing = this.get('task.weighingIncluded');
      const warehouseIncludesWeighing = this.get('step.warehouse.weighingIncluded');

      if (taskIncludesWeighing && !warehouseIncludesWeighing) {
        return checkpointName;
      }

      return altCheckpointName || checkpointName;
    }),
    stageName: Ember.computed('task.stageName', 'name', function () {
      const propertyName = this.get('languageService').findProperLanguageVersion('task.stageName', 'task.stageNameEn');
      return this.get(propertyName) || this.get('intl').t('task.defaultStagePrefix') + ' ' + this.get('name');
    }),
    stageNameEnTrimmed: Ember.computed('task.stageNameEn', 'step.warehouse.nameInAppropriateLanguage', function () {
      let stageNameEn = this.get('task.stageNameEn') ? this.get('task.stageNameEn') : this.get('intl').t('task.defaultStagePrefix') + ' ' + this.get('name');

      if (this.get('step.warehouse')) {
        stageNameEn = stageNameEn.concat(' ', this.get('step.warehouse.nameInAppropriateLanguage'));
      }

      return stageNameEn.replace(/ /g, '-');
    }),
    checkpointGroup: Ember.computed('task.checkpointGroup', function () {
      return this.get('task.checkpointGroup');
    }),
    idxInGroup: Ember.computed('task.idxInGroup', function () {
      return this.get('task.idxInGroup');
    }),
    tracked: Ember.computed('task.tracked', function () {
      return this.get('task.tracked');
    }),
    currentWeight: Ember.computed('transport.enabledWeights.@each.enabled', function () {
      const weights = this.get('transport.enabledWeights').filterBy('enabled');
      return weights.filter(w => w.get('task.id') === this.get('task.id')).get('firstObject');
    }),
    hasUnsynchronizedWeight: Ember.computed('currentWeight', function () {
      return localStorage['weight_'.concat(this.get('task.id'), '_', this.get('transport.id'))];
    }),
    showNetWeight: Ember.computed('checkpoint.task.isLastWeighingTask', 'transport.netWeight', function () {
      const isWarehouseWithWeighing = this.get('step.warehouse.weighingIncluded');
      const entryWeightTaskId = this.get('transport.entryWeight.task.id');
      const isDeparture = !Ember.isEmpty(this.get('transport.entryWeight')) && this.get('task.id') !== entryWeightTaskId;
      return !Ember.isEmpty(this.get('transport.netWeight')) && isWarehouseWithWeighing && isDeparture;
    }),
    transportCustomFields: Ember.computed('transport.customFields', function () {
      return this.get('transport.customFields');
    }),
    customFieldsPairsWithCurrentTaskBlockade: Ember.computed('id', 'transport.{firstUnconfirmedCheckpoint,customFieldPairs}', 'transportCustomFields.@each.{value,isDirty}', function () {
      const self = this;
      return this.get('transport.customFieldPairs').filter(cfp => {
        const visibilityDependsOnDefinition = cfp.definition ? !!cfp.definition.get('visibilityDependsOnDefinition.id') : false;
        const isVisibleBasedOnDefinition = cfp.value ? !!cfp.value.get('isVisibleBasedOnDefinition') : false;
        const currentTaskMatch = cfp.definition.get('task.id') === self.get('task.id');

        if (visibilityDependsOnDefinition) {
          return currentTaskMatch && isVisibleBasedOnDefinition;
        }

        return currentTaskMatch;
      });
    }),
    hasCustomFieldsBlockade: Ember.computed('customFieldsPairsWithCurrentTaskBlockade', function () {
      let hasBlockade = false;
      this.get('customFieldsPairsWithCurrentTaskBlockade').forEach(cfp => {
        const isSelectType = cfp.definition.get('type') === 'select';
        const valueIsNotSaved = !cfp.value || !cfp.value.get('id');
        const valueIsDirty = cfp.value && cfp.value.get('isDirty');
        const valueIsEmpty = !cfp.value || !cfp.value.get('value');
        const valueIsChosen = cfp.value && cfp.value.get('option.id');

        if (!isSelectType && (valueIsEmpty || valueIsDirty)) {
          hasBlockade = true;
        } else if (isSelectType && (valueIsNotSaved || !valueIsChosen)) {
          hasBlockade = true;
        }
      });
      return hasBlockade;
    }),
    hasWeightBlockade: Ember.computed('task.weighingIncluded', function () {
      const weightAccess = this.get('sessionAccount').getSettingValue('AUTOMATIC_WEIGHING') === 'true';
      const weightUrl = !!this.get('sessionAccount').getSettingValue('WEIGHT_URL');
      const weightElement = !!this.get('sessionAccount').getSettingValue('WEIGHT_SELECTOR');
      const autoWeight = weightAccess && weightUrl && weightElement;
      return this.get('task.weighingIncluded') && !autoWeight && !this.get('currentWeight');
    }),
    hasCustomModalBlockade: Ember.computed('task.openCustomModal', function () {
      return this.get('task.openCustomModal');
    }),
    hasWarehouseLocationBlockade: Ember.computed('task.requiresWarehouseLocation', 'step.stepWarehouseLocations', function () {
      return this.get('task.requiresWarehouseLocation') && this.get('step.stepWarehouseLocations').length < 1;
    }),
    hasDocumentBlockade: Ember.computed('task.canBeConfirmAfterAddingDocumentsForTransport', 'transport.numberOfFiles', function () {
      return this.get('task.canBeConfirmAfterAddingDocumentsForTransport') && this.get('transport.numberOfFiles') === 0;
    }),
    confirmButtonShouldBeDisabled: Ember.computed('hasDocumentBlockade', function () {
      return this.get('hasDocumentBlockade');
    }),
    checkpointBlockadeInfo: Ember.computed('confirmButtonShouldBeDisabled', function () {
      const message = Ember.Object.create({
        title: '',
        fields: ''
      });

      if (this.get('hasDocumentBlockade')) {
        message.title = this.get('intl').t('task.blockade.documentRestriction');
        message.fields = '';
      }

      return message;
    }),
    shouldConfirmationButtonBeVisible: Ember.computed('id', 'transport.firstUnconfirmedCheckpoint.id', 'task.canConfirmPrevious', function () {
      if (this.get('id') === this.get('transport.firstUnconfirmedCheckpoint.id') || this.get('transport.firstUnconfirmedCheckpoint.canBeSkipped') && this.get('id') === this.get('transport.firstMandatoryCheckpoint.id')) {
        return true;
      } else {
        return this.get('task.canConfirmPrevious');
      }
    }),
    classificationResourceString: Ember.computed('name', 'executionDate', function () {
      const formattedDate = this.get('executionDate') ? this.dateFormatterService.formatShortTime(this.get('executionDate')) : '';
      return `${this.get('name')} ${formattedDate}`;
    }),
    canBeSkipped: Ember.computed('transport.firstUnconfirmedCheckpoint.id', function () {
      const self = this;
      return !!this.get('sessionAccount.authorityRestrictions').find(authorityRestriction => {
        return authorityRestriction.get('authority.name') === 'ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION' && authorityRestriction.get('task.id') === self.get('task.id');
      });
    }),
    canBeBlocked: Ember.computed('task.canBeBlocked', 'isConfirmed', function () {
      return this.get('task.canBeBlockedByRole') && !this.get('isConfirmed');
    }),
    isWeighingIncludedInStepWarehouse: Ember.computed('step.warehouse.weighingIncluded', function () {
      return this.get('step.warehouse').get('weighingIncluded');
    }),
    transportCustomFieldDefinitions: Ember.computed('transport.customFields.[]', function () {
      return this.get('transport').get('customFields').filter(cf => cf.get('definition'));
    }),
    isCustomFieldRequiredInDelayedCheckpoint: Ember.computed('transportCustomFieldDefinitions.delayedTask.stageName', 'task.stageName', function () {
      const customFieldWithDelayedTask = this.get('transport').get('customFields').filter(cf => {
        return cf.get('definition.delayedTask').get('stageName') === this.get('task').get('stageName');
      }).get('firstObject');

      if (customFieldWithDelayedTask === undefined) {
        return false;
      }

      const cfd = customFieldWithDelayedTask.get('definition');
      const cfdTimeInvervalValue = cfd.get('timeIntervalValue');
      const cfdTimeInvervalUnit = cfd.get('timeIntervalUnit');
      const checkpointExpectedDate = this.get('expectedDate');
      const expectedDateWithAllowedTimeFromCfd = moment(checkpointExpectedDate).add(cfdTimeInvervalValue, cfdTimeInvervalUnit).toDate();
      return moment(expectedDateWithAllowedTimeFromCfd).isBefore(moment().toDate());
    }),
    timeWindow: Ember.computed('step.timeWindow', function () {
      return this.get('step.timeWindow');
    }),
    transportHasWindow: Ember.computed('transport.timeWindows.[]', function () {
      return this.get('transport.timeWindows.length') > 0;
    }),
    transportHasAdvice: Ember.computed('transport.hasAdvice,timeWindows.[]', function () {
      return this.get('transport.hasAdvice');
    }),
    isBeforeAdvice: Ember.computed('transport.checkpoints.@each.idx,task', 'idx', function () {
      const adviceCps = this.get('transport.checkpoints').filterBy('task.shouldBeApprovedWithAdvice');

      if (!adviceCps || adviceCps.length < 0) {
        return false;
      }

      return this.get('idx') < adviceCps.get('firstObject.idx');
    }),
    isBeforeTimeWindow: Ember.computed('transport.checkpoints.@each.idx,task', 'idx', function () {
      const twCps = this.get('transport.checkpoints').filterBy('task.shouldBeApprovedWithTimeWindow');

      if (!twCps || twCps.length < 0) {
        return false;
      }

      return this.get('idx') < twCps.get('firstObject.idx');
    }),
    lastConfirmedCheckpoint: Ember.computed.reads('transport.lastConfirmedCheckpoint'),
    transportHasAdviceOrTimeWindow: Ember.computed('lastConfirmedCheckpoint.{isBeforeAdvice,isBeforeTimeWindow}', 'transport.{hasAdvice,hasTimeWindow}', function () {
      return this.get('transport.hasAdvice') && this.get('isBeforeAdvice') || this.get('transport.hasTimeWindow') && this.get('isBeforeTimeWindow');
    })
  });

  _exports.default = _default;
});