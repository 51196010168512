define("apollo/pods/weight/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    task: _emberData.default.belongsTo('task', {
      async: true
    }),
    warehouse: _emberData.default.belongsTo('warehouse', {
      async: true
    }),
    transport: _emberData.default.belongsTo('transport', {
      async: true,
      inverse: 'weights'
    }),
    confirmator: _emberData.default.belongsTo('user', {
      async: true
    }),
    readOut: _emberData.default.attr('number'),
    enabled: _emberData.default.attr('boolean'),
    dateCreated: _emberData.default.attr('date'),
    notSynchronized: Ember.computed('readOut', 'id', function () {
      return this.get('readOut') && !this.get('id');
    }),
    validations: {
      readOut: {
        presence: {
          message: 'blank'
        }
      }
    }
  });

  _exports.default = _default;
});