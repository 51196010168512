define("apollo/pods/components/transports/transports-table/transport-row/guard-checkpoint-fields/confirmed-checkpoint-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MVMc6Ig/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"checkpoint\",\"executionDate\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[1,[27,\"my-format-date\",[[23,[\"checkpoint\",\"executionDate\"]],\"defaultDateTime\"],null],false],[10],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"checkpoint\",\"task\",\"weighingIncluded\"]],[23,[\"checkpoint\",\"step\",\"warehouse\",\"weighingIncluded\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"p\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"transport.weight\"],null],false],[0,\": \"],[1,[23,[\"checkpoint\",\"currentWeight\",\"readOut\"]],false],[0,\"\\n            \"],[1,[27,\"t\",[\"transport.weightSection.unit\"],null],false],[0,\"\\n\"],[4,\"if\",[[23,[\"checkpoint\",\"hasUnsynchronizedWeight\"]]],null,{\"statements\":[[0,\"                \"],[7,\"span\"],[11,\"class\",\"fa fa-exclamation-circle no-horizontal-padding center-icon-in-form\"],[12,\"title\",[28,[[27,\"t\",[\"transport.weightSection.notSynchronized\"],null]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"checkpoint\",\"showNetWeight\"]],[27,\"not\",[[27,\"eq\",[[27,\"warehouse-net-weight\",null,[[\"weights\",\"warehouse\"],[[23,[\"transport\",\"enabledWeights\"]],[23,[\"checkpoint\",\"step\",\"warehouse\"]]]]],\"NaN\"],null]],null]],null]],null,{\"statements\":[[0,\"            \"],[7,\"p\"],[9],[0,\"\\n                \"],[1,[27,\"t\",[\"transport.weightSection.netWeight\"],null],false],[0,\": \"],[1,[27,\"warehouse-net-weight\",null,[[\"weights\",\"warehouse\"],[[23,[\"transport\",\"enabledWeights\"]],[23,[\"checkpoint\",\"step\",\"warehouse\"]]]]],false],[0,\" \"],[1,[27,\"t\",[\"transport.weightSection.unit\"],null],false],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transports/transports-table/transport-row/guard-checkpoint-fields/confirmed-checkpoint-text/template.hbs"
    }
  });

  _exports.default = _default;
});