define("apollo/services/config-service", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * Get a configuration value with optional default
     * @param {String} key - The config key to retrieve
     * @param {*} defaultValue - Default value to return if key is not found
     * @returns {*} The config value or default
     */
    get(key, defaultValue = null) {
      return key in _environment.default ? _environment.default[key] : defaultValue;
    },

    /**
     * Check if a configuration value exists
     * @param {String} key - The config key to check
     * @returns {Boolean} Whether the key exists
     */
    has(key) {
      return key in _environment.default;
    },

    environment: Ember.computed(function () {
      return _environment.default.environment;
    }),
    isProduction: Ember.computed('environment', function () {
      return this.get('environment') === 'production';
    }),
    isDevelopment: Ember.computed('environment', function () {
      return this.get('environment') === 'development';
    }),
    isTest: Ember.computed('environment', function () {
      return this.get('environment') === 'test';
    })
  });

  _exports.default = _default;
});