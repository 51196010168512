define("apollo/pods/auction/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    sessionAccount: Ember.inject.service('session-account'),
    creator: belongsTo('user', {
      async: true
    }),
    currency: belongsTo('currency', {
      async: true
    }),
    transport: belongsTo('transport'),
    companies: hasMany('company', {
      async: true
    }),
    uninterestedCompanies: hasMany('company', {
      async: true
    }),
    offers: hasMany('offer', {
      async: true
    }),
    initialFinishTime: attr('date'),
    plannedFinishTime: attr('date'),
    actualFinishTime: attr('date'),
    minPrice: attr('number'),
    maxPrice: attr('number'),
    dateCreated: attr('date'),
    otherRequirements: attr('string'),
    unitAmountType: belongsTo('transactionUnitAmountType', {
      async: true
    }),
    validations: {
      currency: {
        presence: {
          message: 'blank'
        }
      },
      companies: {
        custom: {
          validation: function (key, value) {
            return value && value.get('length') >= 1;
          },
          message: 'empty'
        }
      },
      minPrice: {
        numericality: {
          allowBlank: true,
          greaterThanOrEqualTo: 0,
          message: 'invalidNumber'
        },
        custom: [{
          validation: function (key, value, model) {
            const auctionSetting = model.get('auctionSetting');
            /**
             * Pole nie jest wymagane gdy zachodzi przynajmniej jeden z przypadków:
             * - nie ma ustawienia AuctionSetting dla firmy pryncypała,
             * - pole jest niewidoczne - ustawienia AuctionSetting#showPriceRangeFields.
             *
             * W przeciwnym wypadku walidujemy w zależności od ustawienia - AuctionSetting#requireMinPrice.
             * */

            if (!auctionSetting || !auctionSetting.get('showPriceRangeFields')) {
              return true;
            }

            if (auctionSetting.get('requireMinPrice')) {
              return !!value;
            }

            return true;
          },
          message: 'blank'
        }]
      },
      maxPrice: {
        numericality: {
          allowBlank: true,
          greaterThan: 0,
          message: 'invalidNumber'
        },
        custom: [{
          validation: function (key, value, model) {
            const auctionSetting = model.get('auctionSetting');
            /**
             * Pole nie jest wymagane gdy zachodzi przynajmniej jeden z przypadków:
             * - nie ma ustawienia AuctionSetting dla firmy pryncypała,
             * - pole jest niewidoczne - ustawienia AuctionSetting#showPriceRangeFields.
             *
             * W przeciwnym wypadku walidujemy w zależności od ustawienia - AuctionSetting#requireMaxPrice.
             * */

            if (!auctionSetting || !auctionSetting.get('showPriceRangeFields')) {
              return true;
            }

            if (auctionSetting.get('requireMaxPrice')) {
              return !!value;
            }

            return true;
          },
          message: 'blank'
        }, {
          validation: function (key, value, model) {
            if (model.get('minPrice') === undefined || model.get('minPrice') === '' || !value) {
              return true;
            }

            return parseFloat(model.get('minPrice')) <= parseFloat(value);
          },
          message: 'maxPriceLowerThanMinPrice'
        }]
      },
      plannedFinishTime: {
        custom: [{
          validation: function (key, value) {
            return value > new Date();
          },
          message: 'isInPast'
        }]
      },
      unitAmountType: {
        custom: [{
          validation: function (key, value, model) {
            const requireUnitAmountType = model.get('auctionSetting.requireUnitAmountType');
            return !(requireUnitAmountType && !value.get('id'));
          },
          message: 'blank'
        }]
      }
    },
    auctionSetting: Ember.computed('transport.transportType.id', function () {
      return this.get('sessionAccount').getAuctionSetting(this.get('transport.transportType.id'));
    }),
    finished: Ember.computed('actualFinishTime', function () {
      return !!this.get('actualFinishTime');
    }),
    enabledOffers: Ember.computed('offers.@each.enabled', function () {
      return this.get('offers').filterBy('enabled');
    }),
    bestOffer: Ember.computed('enabledOffers.length', 'enabledOffers.@each.place', function () {
      return this.get('enabledOffers').sortBy('place').get('firstObject');
    }),
    currentOfferForLoggedCompany: Ember.computed('enabledOffers.[]', function () {
      const loggedCompany = this.get('sessionAccount.currentUser.company');
      return this.get('enabledOffers').filter(o => o.get('company.id') === loggedCompany.get('id')).get('firstObject');
    }),
    minReductionSize: Ember.computed('auctionSetting.minOfferReductionSize', function () {
      return this.get('auctionSetting.minOfferReductionSize');
    }),
    suggestedPriceForLoggedCompany: Ember.computed('currentOfferForLoggedCompany.price', function () {
      const reductionSize = this.get('minReductionSize');
      const currentOffer = this.get('currentOfferForLoggedCompany');

      if (!currentOffer) {
        return null;
      }

      return Math.max(currentOffer.get('price') - reductionSize, 1);
    }),
    hasBeenProlongated: Ember.computed('plannedFinishTime', 'initialFinishTime', function () {
      return moment(this.get('initialFinishTime')).format('YYYY-MM-DD HH:mm') !== moment(this.get('plannedFinishTime')).format('YYYY-MM-DD HH:mm');
    }),
    hasPreferredOffer: Ember.computed('offers.@each.isPreferred', function () {
      return this.get('enabledOffers').filter(o => o.get('isPreferred')).length > 0;
    }),
    preferredOffer: Ember.computed('enabledOffers.{length,@each.place}', function () {
      return this.get('enabledOffers').filter(o => o.get('isPreferred')).get('firstObject');
    })
  });

  _exports.default = _default;
});