define("apollo/pods/components/server-table/filters/date-range-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      setDate(date) {
        if (!date[0]) {
          this.set('column.filterString', null);
        } // TODO


        if (date.length === 2) {
          this.set('column.filterString', `${date[0]}-${date[1]}`);
        }
      }

    }
  });

  _exports.default = _default;
});