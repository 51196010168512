define("apollo/services/date/locale-timezone-holder-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service for managing user locale and timezone preferences.
   * Acts as a central holder for locale and timezone settings that
   * can be used by other services and components.
   */
  var _default = Ember.Service.extend({
    sessionAccount: Ember.inject.service(),

    /**
     * @returns {string} BCP 47 lanuage tag
     * @see {@link _detectBrowserLocale}
     */
    locale: Ember.computed(function () {
      return null;
    }),

    /**
     * @returns {string} IANA TimeZone ID
     * @see {@link _detectBrowserTimeZone}
     */
    timeZone: Ember.computed(function () {
      return null;
    }),

    /**
     * Event target for locale change notifications
     * Allows services to subscribe to locale changes
     * @type {EventTarget}
     * @private
     */
    localeChangeEventTarget: null,

    /**
     * Event target for timezone change notifications
     * Allows services to subscribe to timezone changes
     * @type {EventTarget}
     * @private
     */
    timeZoneChangeEventTarget: null,

    init() {
      this._super(...arguments);

      this.localeChangeEventTarget = new EventTarget();
      this.timeZoneChangeEventTarget = new EventTarget();
      this.setLocale(this._detectBrowserLocale());
      this.setTimeZone(this._detectBrowserTimeZone());
    },

    setLocale(locale) {
      console.debug(`Changing locale from ${this.locale} to ${locale}`);
      this.set('locale', locale); // Notify subscribers via event

      this.localeChangeEventTarget.dispatchEvent(new CustomEvent('localeChanged', {
        detail: {
          locale
        }
      }));

      this._persistUserPreference('locale', locale);
    },

    setTimeZone(timeZone) {
      console.debug(`Changing timezone from ${this.timeZone} to ${timeZone}`);
      this.set('timeZone', timeZone); // Notify subscribers via event

      this.timeZoneChangeEventTarget.dispatchEvent(new CustomEvent('timeZoneChanged', {
        detail: {
          timeZone
        }
      }));

      this._persistUserPreference('timeZone', timeZone);
    },

    /**
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/supportedValuesOf#supported_time_zone_identifiers}
     * @returns {string[]}
     */
    supportedTimeZones() {
      return Intl.supportedValuesOf('timeZone');
    },

    use12HourClock() {
      const formattedHour = new Intl.DateTimeFormat(this.locale, {
        hour: 'numeric'
      }).format();
      return formattedHour.includes('AM') || formattedHour.includes('PM');
    },

    /**
     * Subscribe to locale changes
     * @param {Function} callback - Function to call when locale changes
     * @returns {Function} Unsubscribe function
     */
    onLocaleChange(callback) {
      const handler = event => callback(event.detail.locale);

      this.localeChangeEventTarget.addEventListener('localeChanged', handler); // Return unsubscribe function

      return () => {
        this.localeChangeEventTarget.removeEventListener('localeChanged', handler);
      };
    },

    /**
     * Subscribe to timezone changes
     * @param {Function} callback - Function to call when timezone changes
     * @returns {Function} Unsubscribe function
     */
    onTimeZoneChange(callback) {
      const handler = event => callback(event.detail.timeZone);

      this.timeZoneChangeEventTarget.addEventListener('timeZoneChanged', handler); // Return unsubscribe function

      return () => {
        this.timeZoneChangeEventTarget.removeEventListener('timeZoneChanged', handler);
      };
    },

    /**
     * Detect the user's browser locale setting
     * Uses the navigator.languages array if available, falling back to navigator.language
     *
     * @returns {string} Detected locale string (e.g., 'en-US') as BCP 47 lanuage tag
     * @see BCP 47 - https://en.wikipedia.org/wiki/IETF_language_tag
     * @private
     */
    _detectBrowserLocale() {
      // Try to get from navigator - browsers provide different ways to access this
      const browserLocales = navigator.languages || [navigator.language || navigator.userLanguage]; // Use the first (most preferred) locale or fall back to 'en-US'

      const detectedLocale = browserLocales[0] || 'en-US';
      console.debug(`Detected browser locale: ${detectedLocale}`);
      return detectedLocale;
    },

    /**
     * Detect the user's browser timezone setting
     * Uses the Intl.DateTimeFormat API to get the resolved timezone
     *
     * @returns {string} Detected timezone string (e.g., 'America/New_York') as IANA TimeZone ID
     * @see IANA time zone database - https://www.iana.org/time-zones
     * @private
     */
    _detectBrowserTimeZone() {
      // Use Intl API to get the browser's timezone or fall back to UTC
      const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
      console.debug(`Detected browser timezone: ${detectedTimeZone}`);
      return detectedTimeZone;
    },

    _getPersistedUserPreference(key) {
      const currentUser = this.get('sessionAccount.currentUser');

      if (currentUser) {
        return this.get('sessionAccount').get(key);
      }
    },

    _persistUserPreference(key, value) {
      const currentUser = this.get('sessionAccount.currentUser');

      if (currentUser) {
        this.get('sessionAccount').set(key, value);
      }
    },

    /**
     * Clean up event listeners when the service is destroyed
     *
     * @method willDestroy
     * @override
     */
    willDestroy() {
      this._super.willDestroy();

      console.debug('Cleaning up before destruction');
      this.localeChangeEventTarget = null;
      this.timeZoneChangeEventTarget = null;
    }

  });

  _exports.default = _default;
});