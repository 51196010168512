define("apollo/pods/addresses/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      max: {
        refreshModel: true
      }
    },
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_COMPANIES_TAB', 'ROLE_OWN_COMPANY_TAB'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },

    async model(params) {
      params.max = params.max || 10;
      params.page = params.page || 1;
      params.isHeadquarters = false;
      const addresses = await this.store.query('address', params);
      const company = await this.store.findRecord('company', params.companyId);
      return {
        addresses,
        company
      };
    },

    actions: {
      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});