define("apollo/utils/functions/date/is-valid-iana-zone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isValidIanaZone = isValidIanaZone;

  /**
   * Validates if a string is a valid IANA timezone identifier.
   * @see IANA time zone database {@link https://www.iana.org/time-zones}
   * @param {string} timeZone - The timezone string to validate (e.g., "America/New_York", "Europe/London")
   * @returns {boolean} True if the timezone is valid, false otherwise
   *
   * @example
   * isValidIanaZone("Europe/Warsaw")
   * /=> true
   *
   * @example
   * isValidIanaZone("Mordor")
   * /=> false
   *
   * @example
   * isValidIanaZone(null)
   * /=> false
   */
  function isValidIanaZone(timeZone) {
    if (!timeZone) {
      return false;
    }

    try {
      new Intl.DateTimeFormat('en-US', {
        timeZone
      }).format();
      return true;
    } catch (e) {
      return false;
    }
  }
});