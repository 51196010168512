define("apollo/services/date/date-formatter-service", ["exports", "apollo/utils/date-formatter"], function (_exports, _dateFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    localeTimezoneHolder: Ember.inject.service('date.locale-timezone-holder-service'),

    formatDefaultDateTime(date, options = {}) {
      const formatter = this._getFormatterWithOptions(options);

      return formatter.formatDefaultDateTime(date).valueOr('Invalid Date');
    },

    formatFullDateTime(date, options = {}) {
      const formatter = this._getFormatterWithOptions(options);

      return formatter.formatFullDateTime(date).valueOr('Invalid Date');
    },

    formatFullDate(date, options = {}) {
      const formatter = this._getFormatterWithOptions(options);

      return formatter.formatFullDate(date).valueOr('Invalid Date');
    },

    formatMediumDate(date, options = {}) {
      const formatter = this._getFormatterWithOptions(options);

      return formatter.formatMediumDate(date).valueOr('Invalid Date');
    },

    formatShortDate(date, options = {}) {
      const formatter = this._getFormatterWithOptions(options);

      return formatter.formatShortDate(date).valueOr('Invalid Date');
    },

    formatFullTime(date, options = {}) {
      const formatter = this._getFormatterWithOptions(options);

      return formatter.formatFullTime(date).valueOr('Invalid Date');
    },

    formatShortTime(date, options = {}) {
      const formatter = this._getFormatterWithOptions(options);

      return formatter.formatShortTime(date).valueOr('Invalid Date');
    },

    formatCustom(date, formatOptions = {}, options = {}) {
      const formatter = this._getFormatterWithOptions(options);

      return formatter.formatCustom(date, formatOptions).valueOr('Invalid Date');
    },

    /**
     * @param {Object} options - Override options
     * @param {string} [options.locale] - Locale override
     * @param {string} [options.timeZone] - Timezone override
     * @returns {DateFormatter} A formatter instance
     * @private
     */
    _getFormatterWithOptions({
      locale,
      timeZone
    } = {}) {
      const effectiveLocale = locale || this.localeTimezoneHolder.locale;
      const effectiveTimeZone = timeZone || this.localeTimezoneHolder.timeZone;
      return new _dateFormatter.default(effectiveLocale, effectiveTimeZone);
    }

  });

  _exports.default = _default;
});