define("apollo/utils/functions/date/get-offset-relative-to", ["exports", "apollo/utils/functions/date/get-offset"], function (_exports, _getOffset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getOffsetRelativeTo = getOffsetRelativeTo;

  /**
   * @description Calculates the absolute time difference in minutes between two timezones.
   *
   * @param {string} timeZone - IANA timezone string for the first timezone (e.g., "America/New_York")
   * @param {string} secondTimeZone - IANA timezone string for the second timezone (e.g., "Asia/Tokyo")
   * @returns {number} The absolute difference in minutes between the two timezones
   *
   * @example
   * // Get time difference between New York and Tokyo
   * const difference = getOffsetRelativeTo('America/New_York', 'Asia/Tokyo');
   * console.log(`The difference between New York and Tokyo is ${difference} minutes`);
   * /=> "The difference between New York and Tokyo is 840 minutes"
   *
   * @example
   * // Get time difference between London and Paris
   * const europeDiff = getOffsetRelativeTo('Europe/London', 'Europe/Paris');
   * console.log(`The difference between London and Paris is ${europeDiff} minutes`);
   * /=> "The relative offset between London and Paris is -60 minutes"
   */
  function getOffsetRelativeTo(timeZone, secondTimeZone) {
    return (0, _getOffset.getOffset)(timeZone) - (0, _getOffset.getOffset)(secondTimeZone);
  }
});