define("apollo/pods/components/locale-aware-flatpickr/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * LocaleAwareFlatpickr - A wrapper for ember-flatpickr that integrates with DateFormatterService.
   * This component is aware of current user locale and timezone and will adjust it's formatting based on that.
   *
   * Usage example:
   * {{locale-aware-flatpickr
   *   date=this.date
   *   onChange=(action 'action')
   *   formatType="defaultDateTime"
   *   locale="en-US"
   *   timeZone="UTC"
   *   nativeFlatpickrOptions=(hash
   *     enableTime=true
   *   )
   * }}
   *
   * SAUCE: {@link https://flatpickr.js.org/options/}
   */
  var _default = Ember.Component.extend({
    tagName: '',
    date: null,
    onChange: null,
    onOpen: null,
    onClose: null,
    class: '',
    formatType: 'defaultDateTime',
    nativeFlatpickrOptions: null,
    localeTimezoneHolder: Ember.inject.service('date.locale-timezone-holder-service'),
    dateFormatterService: Ember.inject.service('date.date-formatter-service'),
    use12HourTime: Ember.computed('localeTimezoneHolder.locale', function () {
      return this.localeTimezoneHolder.use12HourClock();
    }),

    /**
     * Determine which formatter function to use based on formatType
     */
    formatterFunction: Ember.computed('formatType', function () {
      const formatType = this.get('formatType') || 'defaultDateTime';
      const formatterMap = {
        fullDateTime: 'formatFullDateTime',
        fullDate: 'formatFullDate',
        mediumDate: 'formatMediumDate',
        shortDate: 'formatShortDate',
        defaultDateTime: 'formatDefaultDateTime',
        fullTime: 'formatFullTime',
        shortTime: 'formatShortTime'
      };
      return formatterMap[formatType] || 'formatDefaultDateTime';
    }),

    formatDate(date) {
      if (!date) return '';
      const dateFormatterService = this.get('dateFormatterService');
      const formatterFn = this.get('formatterFunction');

      const options = this._getFormatterOptions();

      return dateFormatterService[formatterFn](date, options);
    },

    _getFormatterOptions() {
      const options = {};
      const locale = this.get('locale');
      const timeZone = this.get('timeZone');

      if (locale) {
        options.locale = locale;
      }

      if (timeZone) {
        options.timeZone = timeZone;
      }

      return options;
    },

    mergedFlatpickrOptions: Ember.computed('nativeFlatpickrOptions', 'formatterFunction', 'class', function () {
      const self = this;
      const defaultOptions = {
        altInput: true,
        allowInput: true,
        altInputClass: this.get('class')
      };
      const userOptions = this.get('nativeFlatpickrOptions') || {};
      return Object.assign({}, defaultOptions, userOptions, {
        // Override the formatDate function to use our DateFormatter service
        formatDate: function (dateObj, format) {
          if (isNaN(dateObj.getTime())) {
            return '';
          }

          return self.formatDate(dateObj);
        }
      });
    }),
    actions: {
      handleChange(...args) {
        const onChange = this.get('onChange');

        if (onChange && typeof onChange === 'function') {
          onChange(...args);
        }
      },

      handleOpen(...args) {
        const onOpen = this.get('onOpen');

        if (onOpen && typeof onOpen === 'function') {
          onOpen(...args);
        }
      },

      handleClose(...args) {
        const onClose = this.get('onClose');

        if (onClose && typeof onClose === 'function') {
          onClose(...args);
        }
      }

    }
  });

  _exports.default = _default;
});