define("apollo/pods/components/sections/section-state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GEIjKUfq",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"t\",[[27,\"concat\",[\"section.state.\",[23,[\"section\",\"state\"]]],null]],null],false],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[27,\"not\",[[23,[\"section\",\"deleted\"]]],null],[27,\"eq\",[[23,[\"section\",\"state\"]],\"ARCHIVED\"],null]],null]],null,{\"statements\":[[0,\"    (\"],[1,[27,\"t\",[\"section.expired\"],null],false],[0,\")\\n    \"],[7,\"p\"],[9],[7,\"small\"],[9],[0,\" \"],[1,[27,\"t\",[\"section.dateOfExpiration\"],null],false],[0,\" \"],[1,[27,\"my-format-date\",[[23,[\"section\",\"lastUpdated\"]],\"defaultDateTime\"],null],false],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"and\",[[23,[\"section\",\"deleted\"]],[27,\"eq\",[[23,[\"section\",\"state\"]],\"ARCHIVED\"],null]],null]],null,{\"statements\":[[0,\"    (\"],[1,[27,\"t\",[\"section.disabled\"],null],false],[0,\")\\n    \"],[7,\"p\"],[9],[7,\"small\"],[9],[0,\" \"],[1,[27,\"t\",[\"section.dateOfArchiving\"],null],false],[0,\" \"],[1,[27,\"my-format-date\",[[23,[\"section\",\"lastUpdated\"]],\"defaultDateTime\"],null],false],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"section\",\"activeAction\"]],[27,\"is-in-list\",[[23,[\"section\",\"state\"]],\"['ACCEPTED', 'REJECTED']\"],null]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"my-format-date\",[[23,[\"section\",\"activeAction\",\"dateCreated\"]],\"defaultDateTime\"],null],false],[0,\"\\n\"],[4,\"if\",[[23,[\"section\",\"activeAction\",\"person\",\"id\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"common.by\"],null],false],[0,\" \"],[1,[23,[\"section\",\"activeAction\",\"person\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/sections/section-state/template.hbs"
    }
  });

  _exports.default = _default;
});