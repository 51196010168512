define("apollo/pods/components/checkpoint/rejection-date-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kgprOKw/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"checkpointRejectionDateShouldBeVisible\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"checkpoint-reject-date\"],[11,\"data-field-name\",\"checkpoint-reject-date\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"checkpoint.rejectionDate\"],null],false],[0,\" \"],[1,[27,\"my-format-date\",[[23,[\"checkpoint\",\"rejectionDate\"]],\"defaultDateTime\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/checkpoint/rejection-date-text/template.hbs"
    }
  });

  _exports.default = _default;
});