define("apollo/utils/functions/date/get-timezone-abbreviation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getTimezoneAbbreviation = getTimezoneAbbreviation;

  /**
   * @description Gets the timezone abbreviation (e.g., EST, PST, GMT) for a specific timezone at a given date.
   *
   * @param {string} timeZone - IANA timezone string (e.g., "America/New_York", "Europe/London")
   * @param {Date} [date=new Date()] - Optional date to get the abbreviation for (can be useful for past or future dates)
   * @returns {string} The timezone abbreviation
   *
   * @example
   * // Get timezone abbreviation for New York
   * const nyAbbr = getTimezoneAbbreviation('America/New_York');
   * console.log(`New York timezone abbreviation: ${nyAbbr}`);
   * /=> "New York timezone abbreviation: EST" (or EDT during daylight saving)
   *
   * @example
   * // Get timezone abbreviation for London in summer
   * const summerDate = new Date('2023-07-15T12:00:00Z');
   * const londonAbbr = getTimezoneAbbreviation('Europe/London', summerDate);
   * console.log(`London timezone abbreviation in July: ${londonAbbr}`);
   * /=> "London timezone abbreviation in July: BST"
   */
  function getTimezoneAbbreviation(timeZone, date = new Date()) {
    try {
      // Format the date with the timezone and include the timezone name in the format
      const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone,
        timeZoneName: 'short'
      });
      const formatted = formatter.format(date); // Extract the abbreviation from the formatted string
      // The format will be something like "3/24/2025, GMT+1" or "3/24/2025, EDT"

      return formatted.split(', ')[1];
    } catch (error) {
      console.error(`Error getting timezone abbreviation: ${error.message}`);
      return 'Error getting timezone abbreviation';
    }
  }
});