define("apollo/pods/route-offer/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    company: belongsTo('company', {
      async: true,
      inverse: null
    }),
    confirmator: belongsTo('user', {
      async: true
    }),
    creator: belongsTo('user', {
      async: true
    }),
    route: belongsTo('route', {
      async: true
    }),
    truckType: belongsTo('truck-type', {
      async: true
    }),
    enabled: attr('boolean'),
    price: attr('number'),
    validityDateStart: _emberData.default.attr('date'),
    validityDateEnd: _emberData.default.attr('date'),
    confirmed: attr('boolean'),
    dateConfirmed: _emberData.default.attr('date'),
    dateCreated: _emberData.default.attr('date'),
    currency: Ember.computed('route.currency', function () {
      return this.get('route.currency');
    }),
    isValidToday: Ember.computed('validityDateStart', 'validityDateEnd', function () {
      const now = moment().startOf('day').toDate();
      return this.get('validityDateStart') <= now && this.get('validityDateEnd') >= now;
    }),

    /**
     * Za A-10718. Sortowanie:
     *
     * - Pierwsza --> Oferty, których data ważności jest aktualna (od <= dziś <= do)
     * - Druga --> Oferty, których "data ważności do" jest z przyszłości (dziś < do)
     * - Trzecia --> Oferty, których data ważności jest z przeszłości (od < dziś)
     */
    sortLevel: Ember.computed('validityDateStart', 'validityDateEnd', function () {
      const now = moment().startOf('day').toDate();

      if (this.get('validityDateEnd') < now) {
        return 3;
      }

      if (this.get('validityDateStart') > now) {
        return 2;
      }

      return 1;
    }),
    validations: {
      truckType: {
        custom: [{
          validation: function (key, value, model) {
            if (!model.get('route.transportType.requiresTruckType')) {
              return true;
            }

            return model.get('truckType.id') !== undefined;
          },
          message: 'blankSelect'
        }]
      },
      company: {
        custom: [{
          validation: function (key, value) {
            return value.get('id');
          },
          message: 'blank'
        }]
      },
      price: {
        presence: {
          message: 'blank'
        },
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: Number.MAX_VALUE,
          message: 'noNegativeNumber'
        }
      },
      validityDateStart: {
        presence: {
          message: 'blank'
        }
      },
      validityDateEnd: {
        presence: {
          message: 'blank'
        },
        custom: [{
          validation: function (key, value, model) {
            const validityDateEndValue = moment(value);
            const validityDateStartValue = moment(model.get('validityDateStart'));
            return validityDateEndValue.isAfter(validityDateStartValue);
          },
          message: 'invalidDatesSequence'
        }]
      }
    }
  });

  _exports.default = _default;
});