define("apollo/services/language/ui-language-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    configService: Ember.inject.service(),
    localeTimezoneHolder: Ember.inject.service('date.locale-timezone-holder-service'),
    availableUILanguages: Object.freeze(['cs', 'de', 'es', 'en'
    /* en-GB? */
    , 'it', 'pl', 'tr']),
    defaultUILanguage: Ember.computed(function () {
      return this.get('configService').get('DEFAULT_UI_LANGUAGE', 'en');
    }),
    useLocalTranslations: Ember.computed(function () {
      return this.get('configService').get('useLocalTranslations');
    }),
    translationsUrl: Ember.computed(function () {
      return this.get('configService').get('translationsUrl', 'https://translations.logintegra.eu');
    }),
    _localeChangeUnsubscribe: null,

    async initialize() {
      // Set UI language
      const preferredUILanguage = this._getUserPreferredUILanguage();

      await this._setUILanguage(preferredUILanguage);
      this._localeChangeUnsubscribe = this.get('localeTimezoneHolder').onLocaleChange(this._handleLocaleChange.bind(this));
    },

    isUILanguageSupported(langCode) {
      return this.get('availableUILanguages').includes(langCode);
    },

    /**
     * @private
     */
    _getUserPreferredUILanguage() {
      const locale = this.get('localeTimezoneHolder').locale;

      const localeNoRegion = this._parseToLangWithoutRegion(locale); // We do not distinguish British English from American English


      if (localeNoRegion && this.isUILanguageSupported(localeNoRegion)) {
        return localeNoRegion;
      } // Safely fallback to English


      return 'en';
    },

    /**
     * @private
     */
    _parseToLangWithoutRegion(locale) {
      return locale.split('-')[0];
    },

    /**
     * Handle locale changes from the localeTimezone service
     * @param {string} locale - New locale
     * @private
     */
    _handleLocaleChange(locale) {
      this._setUILanguage(this._parseToLangWithoutRegion(locale));
    },

    /**
     * @private
     */
    async _setUILanguage(langCode) {
      // Ensure we have a valid language
      const language = this.isUILanguageSupported(langCode) ? langCode : this.get('defaultUILanguage');

      try {
        // Load translations for the language
        await this._loadTranslations(language); // Set up Intl with the language and English as fallback for UI translation

        const locales = [language, this.get('defaultUILanguage')];
        this.get('intl').setLocale(locales);

        if (typeof moment !== 'undefined') {
          moment.locale(language || this.get('defaultUILanguage'));
        }

        console.debug(`UI Language set to: ${language}`);
        return language;
      } catch (error) {
        console.error(`Failed to set UI language ${language}:`, error);
        return this._setUILanguage(this.get('defaultUILanguage'));
      }
    },

    async _loadTranslations(language) {
      try {
        // First load English as fallback if not already loaded and language isn't English
        if (language !== 'en') {
          await this._loadLanguageTranslations('en');
        } // Then load the requested language


        await this._loadLanguageTranslations(language);
      } catch (error) {
        console.error(`Failed to load translations for ${language}:`, error); // Add empty translations to prevent errors

        this.get('intl').addTranslations(language, {});
      }
    },

    async _loadLanguageTranslations(language) {
      // Determine source based on configuration
      const localTranslationsFolderName = 'translations';
      const source = this.get('useLocalTranslations') ? localTranslationsFolderName : this.get('translationsUrl');

      try {
        const response = await fetch(`${source}/${language}.json`);

        if (response.ok) {
          const translations = await response.json();
          this.get('intl').addTranslations(language, translations);
          console.debug(`Loaded translations for ${language} from ${source}`);
        } else {
          console.warn(`No translations found for ${language} at ${source}`);
          this.get('intl').addTranslations(language, {});
        }
      } catch (error) {
        console.error(`Error loading translations for ${language} from ${source}:`, error);
        this.get('intl').addTranslations(language, {});
      }
    },

    willDestroy() {
      this._super.willDestroy();

      if (this._localeChangeUnsubscribe) {
        this._localeChangeUnsubscribe();
      }
    }

  });

  _exports.default = _default;
});