define("apollo/pods/section-action/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    person: _emberData.default.belongsTo('user', {
      async: true
    }),
    section: _emberData.default.belongsTo('section', {
      async: true
    }),
    type: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    comment: _emberData.default.attr('string'),
    dateCreated: _emberData.default.attr('date')
  });

  _exports.default = _default;
});