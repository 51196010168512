define("apollo/pods/terms-of-service/model", ["exports", "ember-data", "apollo/pods/document/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;

  var _default = _model.default.extend({
    sessionAccount: service('session-account'),
    confirmator: _emberData.default.belongsTo('user'),
    confirmationDate: _emberData.default.attr('date'),
    isConfirmed: _emberData.default.attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    formattedConfirmationDate: Ember.computed('confirmationDate', function () {
      return moment(this.get('confirmationDate')).format('DD.MM.YYYY HH:mm').toString();
    }),
    formattedExpirationDate: Ember.computed('confirmationDate', function () {
      return moment(this.get('expirationDate')).format('DD.MM.YYYY HH:mm').toString();
    }),
    confirmatorName: Ember.computed('confirmator', function () {
      return this.get('confirmator.username');
    }),
    validations: {
      isConfirmed: {
        custom: [{
          validation: function (key, value) {
            return value;
          },
          message: function (key, value, model) {
            return model.get('intl').t('terms-of-service.errors.unchecked');
          }
        }]
      }
    }
  });

  _exports.default = _default;
});