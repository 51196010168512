define("apollo/helpers/get-schedule-day-name-with-timezone-offset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params, hash) {
      const [dayOffset, day] = params;

      if (dayOffset === 0) {
        return day.name;
      }

      if (dayOffset === -1) {
        return day.nameMinusOneDay;
      }

      if (dayOffset === 1) {
        return day.namePlusOneDay;
      }

      return day.name;
    }

  });

  _exports.default = _default;
});