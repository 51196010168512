define("apollo/pods/components/transport/custom-fields-section/custom-field/component", ["exports", "apollo/mixins/transport-form-mixin"], function (_exports, _transportFormMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_transportFormMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    enabledOptionsWithDependency: Ember.computed('', function () {
      const pairs = this.get('transport.customFieldPairs');
      const options = this.get('pair.definition.enabledOptions');
      const avialableOptions = [];
      options.forEach(option => {
        const relatedDef = option.get('relatedDefinition.content');

        if (relatedDef === null) {
          avialableOptions.push(option);
        } else {
          pairs.forEach(pair => {
            if (pair.definition === relatedDef) {
              const value = pair.value.get('option');
              option.get('relatedOptions').forEach(rOpt => {
                if (rOpt === value.content) {
                  avialableOptions.push(option);
                }
              });
            }
          });
        }
      });
      return avialableOptions;
    }),
    actions: {
      setOption(pair, option) {
        const self = this;
        pair.value.set('option', option);
        pair.value.set('hasDirtyAttributes', true); // Żeby znikały czerwone ramki, gdy nie są już potrzebne.

        if (pair.value.get('errors.length') > 0) {
          pair.value.validate();
        }

        if (pair.definition.get('isSelectType') && pair.value.get('option.transportPanel')) {
          const transportPanelClass = pair.definition.get('TRANSPORT_PANEL_CLASS')[pair.value.get('option.transportPanel')];
          self.openPanel(transportPanelClass);
        }

        this.get('transport').notifyPropertyChange('customFieldPairs');
      },

      setCfDateValue(date) {
        if (!date) {
          return;
        }

        const firstDate = date.get('firstObject');

        if (!firstDate) {
          return;
        } // Ensure we have a valid date object


        const dateObject = firstDate instanceof Date ? firstDate : new Date(firstDate);

        if (!isNaN(dateObject.getTime())) {
          this.set('pair.value.value', dateObject.toISOString());
        } else {
          console.debug('Invalid date provided to Date custom field');
        }
      },

      setCustomFieldData(pair, option) {
        pair.value.set('value', option.value || option);
      },

      removeCustomField(pair) {
        if (!pair.value) {
          return;
        }

        pair.value.set('value', null);
      }

    }
  });

  _exports.default = _default;
});