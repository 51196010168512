define("apollo/pods/product/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    company: _emberData.default.belongsTo('company', {
      async: false
    }),
    index: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    shortName: _emberData.default.attr('string'),
    unit: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean'),
    lastUpdated: _emberData.default.attr('date'),
    validations: {
      index: {
        presence: {
          message: 'blank'
        },
        custom: {
          validation: function (key, value) {
            return !value || value.length <= 255;
          },
          message: 'tooLong'
        }
      },
      name: {
        presence: {
          message: 'blank'
        },
        custom: {
          validation: function (key, value) {
            return !value || value.length <= 255;
          },
          message: 'tooLong'
        }
      },
      shortName: {
        presence: {
          message: 'blank'
        },
        custom: {
          validation: function (key, value) {
            return !value || value.length <= 255;
          },
          message: 'tooLong'
        }
      },
      unit: {
        presence: {
          message: 'blankSelect'
        }
      }
    }
  });

  _exports.default = _default;
});