define("apollo/pods/components/date-formatter-test/component", ["exports", "apollo/utils/functions/date/get-offset", "apollo/utils/functions/date/get-offset-relative-to"], function (_exports, _getOffset, _getOffsetRelativeTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dateFormatterService: Ember.inject.service('date.date-formatter-service'),
    testDate: null,
    dateInput: '',
    localeInput: '',
    timeZoneInput: '',
    fallbackInput: 'Invalid Date',
    // Timer for auto-update
    autoUpdateTimer: null,
    autoUpdateEnabled: false,
    flatpickrDate: null,
    flatpickrDateTimestamp: Ember.computed('flatpickrDate', function () {
      if (Ember.get(this, 'flatpickrDate')) {
        return Ember.get(this, 'flatpickrDate.firstObject').getTime();
      }

      return null;
    }),
    flatpickrShortDate: null,
    flatpickrShortDateTimestamp: Ember.computed('flatpickrShortDate', function () {
      if (Ember.get(this, 'flatpickrShortDate')) {
        return Ember.get(this, 'flatpickrShortDate.firstObject').getTime();
      }

      return null;
    }),
    results: Object.freeze([]),
    availableLocales: Ember.computed(function () {
      return [{
        id: 'cs',
        name: 'Česky'
      }, {
        id: 'de',
        name: 'Deutsch'
      }, {
        id: 'es',
        name: 'Español'
      }, {
        id: 'en',
        name: 'English'
      }, {
        id: 'it',
        name: 'Italiano'
      }, {
        id: 'pl',
        name: 'Polski'
      }, {
        id: 'tr',
        name: 'Türkçe'
      }];
    }),
    availableTimeZones: Ember.computed(function () {
      return this.localeTimezoneHolder.supportedTimeZones().map(ianaLocale => {
        return {
          id: ianaLocale,
          name: ianaLocale.split('/')[1]
        };
      });
    }),
    timeZoneOffsetForTimeZoneInput: null,
    timeZoneOffsetRelativeToPoland: null,

    // Initialize with the current date
    init() {
      this._super(...arguments);

      const now = new Date();
      Ember.set(this, 'testDate', now);
      Ember.set(this, 'dateInput', now.toISOString().substring(0, 16)); // Format for datetime-local input

      Ember.set(this, 'autoUpdateEnabled', true); // Default to auto-update enabled

      this.runAllTests();

      this._startAutoUpdate();
    },

    willDestroy() {
      this._super(...arguments);

      this._stopAutoUpdate();
    },

    _startAutoUpdate() {
      if (Ember.get(this, 'autoUpdateEnabled')) {
        // Create a timer that updates the date every second
        const timer = Ember.run.later(this, function () {
          this.setToNow();

          this._startAutoUpdate(); // Schedule the next update

        }, 1000);
        Ember.set(this, 'autoUpdateTimer', timer);
      }
    },

    _stopAutoUpdate() {
      const timer = Ember.get(this, 'autoUpdateTimer');

      if (timer) {
        Ember.run.cancel(timer);
        Ember.set(this, 'autoUpdateTimer', null);
      }
    },

    toggleAutoUpdate() {
      const currentState = Ember.get(this, 'autoUpdateEnabled');
      Ember.set(this, 'autoUpdateEnabled', !currentState);

      if (!currentState) {
        this._startAutoUpdate();
      } else {
        this._stopAutoUpdate();
      }
    },

    updateDate(event) {
      Ember.set(this, 'dateInput', event.target.value);
      Ember.set(this, 'testDate', new Date(event.target.value));
    },

    updateLocale(locale) {
      Ember.set(this, 'localeInput', locale.id);
    },

    updateTimeZone(tz) {
      Ember.set(this, 'timeZoneInput', tz.id);
    },

    updateFallback(event) {
      Ember.set(this, 'fallbackInput', event.target.value);
    },

    setToNow() {
      const now = new Date();
      Ember.set(this, 'dateInput', now.toISOString().substring(0, 16));
      Ember.set(this, 'testDate', now);
      this.runAllTests();
    },

    setToInvalidDate() {
      Ember.set(this, 'dateInput', 'Not a date');
      Ember.set(this, 'testDate', new Date(Ember.get(this, 'dateInput')));
      this.runAllTests();
    },

    setToPastDate() {
      const pastDate = new Date();
      pastDate.setFullYear(pastDate.getFullYear() - 1);
      Ember.set(this, 'dateInput', pastDate.toISOString().substring(0, 16));
      Ember.set(this, 'testDate', pastDate);
      this.runAllTests();
    },

    setToFutureDate() {
      const futureDate = new Date();
      futureDate.setFullYear(futureDate.getFullYear() + 1);
      Ember.set(this, 'dateInput', futureDate.toISOString().substring(0, 16));
      Ember.set(this, 'testDate', futureDate);
      this.runAllTests();
    },

    runAllTests() {
      // Create options object based on user inputs
      const options = {};

      if (Ember.get(this, 'localeInput')) {
        options.locale = Ember.get(this, 'localeInput');
      }

      if (Ember.get(this, 'timeZoneInput')) {
        options.timeZone = Ember.get(this, 'timeZoneInput');
      }

      Ember.set(this, 'timeZoneOffsetForTimeZoneInput', (0, _getOffset.getOffset)(Ember.get(this, 'timeZoneInput') || this.localeTimezoneHolder.timeZone));
      Ember.set(this, 'timeZoneOffsetRelativeToPoland', (0, _getOffsetRelativeTo.getOffsetRelativeTo)(Ember.get(this, 'timeZoneInput') || this.localeTimezoneHolder.timeZone, 'Europe/Warsaw')); // Run all formatting tests and collect results

      const results = [{
        name: 'Full Date Time',
        method: 'formatFullDateTime',
        result: this.formatWithMethod('formatFullDateTime', options)
      }, {
        name: 'Full Date',
        method: 'formatFullDate',
        result: this.formatWithMethod('formatFullDate', options)
      }, {
        name: 'Medium Date',
        method: 'formatMediumDate',
        result: this.formatWithMethod('formatMediumDate', options)
      }, {
        name: 'Short Date',
        method: 'formatShortDate',
        result: this.formatWithMethod('formatShortDate', options)
      }, {
        name: 'Full Time',
        method: 'formatFullTime',
        result: this.formatWithMethod('formatFullTime', options)
      }, {
        name: 'Short Time',
        method: 'formatShortTime',
        result: this.formatWithMethod('formatShortTime', options)
      }, {
        name: 'Custom (Month/Year)',
        method: 'formatCustom',
        result: Ember.get(this, 'dateFormatterService').formatCustom(Ember.get(this, 'testDate'), {
          month: 'long',
          year: 'numeric'
        }, options)
      }, {
        name: 'Custom (Weekday)',
        method: 'formatCustom',
        result: Ember.get(this, 'dateFormatterService').formatCustom(Ember.get(this, 'testDate'), {
          weekday: 'long'
        }, options)
      }, {
        name: 'Custom (24h Time)',
        method: 'formatCustom',
        result: Ember.get(this, 'dateFormatterService').formatCustom(Ember.get(this, 'testDate'), {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hourCycle: 'h23'
        }, options)
      }]; // Add helper examples

      results.push({
        name: 'Helper Example (Full)',
        method: 'my-format-date helper',
        result: this.getHelperSyntax('full')
      });
      results.push({
        name: 'Helper Example (Custom)',
        method: 'my-format-date helper',
        result: this.getHelperSyntax('custom')
      });
      Ember.set(this, 'results', results);
    },

    formatWithMethod(methodName, options) {
      // Call the method on the dateFormatter service and apply fallback
      return Ember.get(this, 'dateFormatterService')[methodName](Ember.get(this, 'testDate'), options);
    },

    getHelperSyntax(style) {
      // Generate example helper syntax with current settings
      let syntax = `{{my-format-date date '${style}'`;

      if (Ember.get(this, 'localeInput')) {
        syntax += ` locale="${Ember.get(this, 'localeInput')}"`;
      }

      if (Ember.get(this, 'timeZoneInput')) {
        syntax += ` timeZone="${Ember.get(this, 'timeZoneInput')}"`;
      }

      if (Ember.get(this, 'fallbackInput') !== 'Invalid Date') {
        syntax += ` fallback="${Ember.get(this, 'fallbackInput')}"`;
      }

      if (style === 'custom') {
        syntax += ' year="numeric" month="long" day="numeric"';
      }

      syntax += '}}';
      return syntax;
    },

    setGlobalLocale(locale) {
      Ember.get(this, 'localeTimezoneHolder').setLocale(locale);
      this.runAllTests();
    },

    setGlobalTimeZone(timeZone) {
      Ember.get(this, 'localeTimezoneHolder').setTimeZone(timeZone.id);
      this.runAllTests();
    },

    actions: {
      updateDate(date) {
        this.updateDate(date);

        if (Ember.get(this, 'autoUpdateEnabled')) {
          this.toggleAutoUpdate();
        }
      },

      updateLocale(locale) {
        this.updateLocale(locale);
      },

      updateTimeZone(tz) {
        this.updateTimeZone(tz);
      },

      updateFallback(fallback) {
        this.updateFallback(fallback);
      },

      setToNow() {
        this.setToNow();
      },

      setToInvalidDate() {
        this.setToInvalidDate();

        if (Ember.get(this, 'autoUpdateEnabled')) {
          this.toggleAutoUpdate();
        }
      },

      setToPastDate() {
        this.setToPastDate();
      },

      setToFutureDate() {
        this.setToFutureDate();
      },

      runAllTests() {
        this.runAllTests();
      },

      setGlobalLocale(locale) {
        this.setGlobalLocale(locale);
      },

      setGlobalTimeZone(timeZone) {
        this.setGlobalTimeZone(timeZone);
      },

      toggleAutoUpdate() {
        this.toggleAutoUpdate();
      }

    }
  });

  _exports.default = _default;
});