define("apollo/pods/warehouses/create/controller", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    intl: service(),
    store: service(),
    settingsIndexController: Ember.inject.controller('settings/index'),
    availableTimeWindowLengthsInMinutes: ['15', '30', '45', '60', '90', '120', '150', '180', '240', '300'],
    availableTimeWindowOffsetIntervals: ['15', '30', '45', '60', '90', '120', '150', '180', '240', '300'],
    addNewAddress: false,
    transportTypes: function () {
      return this.get('sessionAccount.currentUser.transportTypes');
    }.property('sessionAccount.currentUser.transportTypes.@each'),
    // http://www.poeticsystems.com/blog/ember-checkboxes-and-you
    proxiedTransportTypes: function () {
      return this.get('transportTypes').map(tt => {
        return Ember.ObjectProxy.create({
          content: tt,
          checked: true
        });
      });
    }.property('transportTypes.@each'),
    numberOfWarehouses: Ember.computed(function () {
      return this.get('store').peekAll('warehouse').length - 1;
    }),
    timeZones: Ember.computed(function () {
      return this.localeTimezoneHolder.supportedTimeZones();
    }),
    setProxiedTransportTypes: function () {
      const self = this;
      Ember.run.once(this, function () {
        self.checkTransportTypes();
      });
    }.observes('proxiedTransportTypes.length', 'model'),
    countries: Ember.computed(function () {
      return this.get('store').peekAll('country').sortBy('ibanSymbol');
    }),

    checkTransportTypes() {
      const proxiedTransportTypes = this.get('proxiedTransportTypes');
      const isEditing = !!this.get('model.id');
      const transportTypeIds = this.get('model').get('transportTypes').map(w => {
        return w.get('id');
      });
      proxiedTransportTypes.forEach(function (w) {
        const checked = !isEditing || transportTypeIds.includes(w.get('id'));
        w.set('checked', checked);
      });
    },

    proxiedCheckedTransportTypes: Ember.computed.filterBy('proxiedTransportTypes', 'checked', true),
    checkedTransportTypes: Ember.computed.mapBy('proxiedCheckedTransportTypes', 'content'),
    companyToFindAddress: Ember.computed('sessionAccount.currentUser.company.id', function () {
      return this.get('model.company.id') ? this.get('model.company.id') : this.get('sessionAccount.currentUser.company.id');
    }),
    address: Ember.computed('model.address', function () {
      return this.get('model.address');
    }),
    addressSummary: Ember.computed('address.recipient', 'address.city', 'address.postal', 'address.street', function () {
      if (this.get('address.recipient')) {
        return this.get('address.recipient');
      }

      if (this.get('address.street')) {
        return `${this.get('address.street')}, ${this.get('address.city')}, ${this.get('address.postal')}`;
      }

      return '';
    }).readOnly(),
    showAssortmentGroupSection: Ember.computed('model.assortmentGroups.[]', function () {
      return this.get('model.assortmentGroups.length') > 0;
    }),
    showWarehouseLocationSection: Ember.computed('model.warehouseLocations.[]', function () {
      return this.get('model.warehouseLocations.length') > 0;
    }),
    actions: {
      save() {
        const self = this;
        const warehouse = this.get('model');
        warehouse.validate();

        if (warehouse.get('errors.length')) {
          return;
        }

        const checkedTransportTypes = this.get('checkedTransportTypes');
        warehouse.get('transportTypes').clear();
        const checkedTransportTypesIds = Ember.A();
        checkedTransportTypes.forEach(function (tt) {
          checkedTransportTypesIds.pushObject(tt.get('id'));
        });
        warehouse.set('checkedTransportTypesIds', checkedTransportTypesIds);
        warehouse.save().then(function () {
          self.setProperties({
            errors: []
          });
          warehouse.get('transportTypes').forEach(w => {
            w.reload();
          });
          warehouse.reload();
          self.get('sessionAccount.currentUser').reload();
          const msg = self.get('intl').t('warehouse.updateSuccessMessage', {
            warehouseName: warehouse.get('nameInAppropriateLanguage')
          });
          self.set('successMessage', msg);
          window.scrollTo({
            top: 0
          });
        }).catch(response => {
          self.set('errors', response.errors);
        });
      },

      disable() {
        const self = this;
        const model = this.get('model');
        const ramps = model.get('ramps');
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        Ember.$.ajax({
          url: _environment.default.serverURL + '/warehouses/' + model.get('id') + '/disable',
          type: 'PUT',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(() => {
          model.reload().then(w => {
            ramps.then(() => {
              const msg = self.get('intl').t('warehouse.disabledMessage', {
                warehouseName: w.get('nameInAppropriateLanguage')
              });
              self.get('settingsIndexController').set('successMessage', msg);
              self.get('sessionAccount').get('currentUser').reload().then(() => {
                self.transitionToRoute('settings.index');
              });
            });
          });
        }, function (response) {
          self.set('errors', response.errors);
        });
      },

      enable() {
        const self = this;
        const model = this.get('model');
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        Ember.$.ajax({
          url: _environment.default.serverURL + '/warehouses/' + model.get('id') + '/enable',
          type: 'PUT',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(() => {
          model.reload().then(w => {
            w.get('ramps').then(ramps => {
              ramps.forEach(r => {
                r.reload();
                console.log(`Ponownie włączono rampę ${r.get('name')}.`);
              });
              self.get('sessionAccount').get('currentUser').reload().then(() => {
                self.transitionToRoute('settings.index');
              });
            });
          });
        }, function (response) {
          self.set('errors', response.errors);
        });
      },

      cancel() {
        this.get('model').rollbackAttributes();
        this.transitionToRoute('settings.index');
      },

      setAddressData(address) {
        const self = this;

        if (address.id) {
          this.get('store').findRecord('address', address.id).then(a => {
            self.get('model').set('address', a);
          });
        } else {
          this.get('model.address').setProperties({
            street: address.street,
            postal: address.postal,
            city: address.city
          });
          this.get('store').findRecord('country', address.country).then(c => {
            self.get('model.address').set('country', c);
          });
        }
      },

      removeAddress() {
        console.debug('Removing address from warehouse..');
        this.get('model').set('address', null);
      },

      toggleNewAddress(model) {
        if (this.get('addNewAddress')) {
          this.send('deleteAddressFromWarehouse', model);
          this.set('addNewAddress', false);
        } else {
          this.send('setAddressForWarehouse', model);
          this.set('addNewAddress', true);
        }
      },

      deleteAddressFromWarehouse(model) {
        model.get('address').then(address => {
          address.unloadRecord();
          model.set('address', null);
        });
      },

      setAddressForWarehouse(model) {
        model.set('address', this.get('store').createRecord('address', {
          company: model.get('company')
        }));
      }

    }
  });

  _exports.default = _default;
});