define("apollo/pods/user/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    hasMany,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    ramps: hasMany('ramp', {
      inverse: 'users'
    }),
    queues: hasMany('queue', {
      inverse: 'users'
    }),
    workflows: hasMany('workflow', {
      async: true
    }),
    roles: hasMany('role', {
      async: true
    }),
    tableColumns: hasMany('tableColumn', {
      async: true
    }),
    userNotifications: hasMany('userNotification', {
      async: true
    }),
    transportTypes: hasMany('transportType', {
      async: true
    }),
    company: belongsTo('company', {
      async: true
    }),
    roleGroup: belongsTo('roleGroup', {
      async: true
    }),
    userGroup: belongsTo('userGroup', {
      async: true
    }),
    whoDeleted: belongsTo('user', {
      async: true
    }),
    invitation: belongsTo('invitation', {
      async: true,
      inverse: 'registeredAccount'
    }),
    invitationCreator: attr('string'),
    invitationDate: attr('date'),
    lastLoginDate: attr('date'),
    localAdmin: attr('boolean'),
    ldapUser: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    waitingInvitationsCount: attr('number'),
    unreadAnnouncementsCount: attr('number'),
    username: attr('string'),
    name: attr('string'),
    email: attr('string'),
    enabled: attr('boolean', {
      defaultValue() {
        return true;
      }

    }),
    password: attr('string'),
    phoneNumber: attr('string'),
    mobilePhoneNumber: attr('string'),
    mobilePhoneNumberPrefix: attr('string'),
    occupation: attr('string'),
    workHours: attr('string'),
    newPassword: attr('string'),
    repeatedNewPassword: attr('string'),
    changePassword: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    confirmedTermsOfProvidingServices: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    accountExpired: attr('boolean'),
    accountLocked: attr('boolean'),
    temporaryAccount: attr('boolean'),
    passwordExpired: attr('boolean'),
    dateCreated: attr('date'),
    dateDeleted: attr('date'),
    resetPasswordDate: attr('date'),
    resetPasswordIsDisabled: attr('boolean'),
    statuteAccepted: attr('boolean'),
    maxRowsInCompaniesTable: attr('number'),
    preferredLanguage: attr('string'),
    availableLanguages: attr(),
    selectedNotifications: Ember.computed('userNotifications.@each.notification', function () {
      return this.get('userNotifications').getEach('notification');
    }),
    warehouses: Ember.computed('ramps.{[],@each.warehouse}', function () {
      return this.get('ramps').filterBy('enabled').getEach('warehouse').filterBy('enabled').filterBy('company.id', localStorage.getItem('principalId')).uniqBy('id').sortBy('idx');
    }),
    accessibleWarehouses: Ember.computed('warehouses.[]', function () {
      if (this.get('sessionAccount').hasRole('ROLE_ACCESS_ALL_RAMPS')) {
        return this.store.peekAll('warehouse').filterBy('enabled').sortBy('idx');
      }

      return this.get('warehouses').sortBy('idx');
    }),
    accessibleRamps: Ember.computed('ramps.[]]', function () {
      if (this.get('sessionAccount').hasRole('ROLE_ACCESS_ALL_RAMPS')) {
        return this.store.peekAll('ramp').filterBy('enabled');
      }

      return this.get('ramps');
    }),
    accessibleSquares: Ember.computed('squares.[]', function () {
      if (this.get('sessionAccount').hasRole('ROLE_ACCESS_ALL_QUEUES')) {
        return this.store.peekAll('square').filterBy('enabled').sortBy('idx');
      }

      return this.get('squares').sortBy('idx');
    }),
    accessibleQueues: Ember.computed('queues.[]', function () {
      if (this.get('sessionAccount').hasRole('ROLE_ACCESS_ALL_QUEUES')) {
        return this.store.peekAll('queue').filterBy('enabled');
      }

      return this.get('queues');
    }),
    warehouse: Ember.computed('warehouses.firstObject', function () {
      return this.get('warehouses').get('firstObject');
    }),
    rampsIds: Ember.computed('ramps.@each.id', function () {
      return this.get('ramps').map(r => r.get('id'));
    }),
    queuesIds: Ember.computed('queues.@each.id', function () {
      return this.get('queues').getEach('id');
    }),
    squares: Ember.computed('queues.{[],@each.square}', function () {
      return this.get('queues').filterBy('enabled').getEach('square').filterBy('enabled').filterBy('company.id', localStorage.getItem('principalId')).uniqBy('id').sortBy('idx');
    }),
    squaresIds: Ember.computed('squares.[]', function () {
      return this.get('squares').getEach('id');
    }),
    roleNames: Ember.computed('roles.{length,@each.name}', function () {
      return this.get('roles').map(function (r) {
        return r.get('name');
      });
    }),
    isPrincipal: Ember.computed('company.isPrincipal', function () {
      return this.get('company').get('isPrincipal');
    }),
    canDiscardAdviceRestrictions: function (transportTypeId) {
      if (!transportTypeId) {
        return false;
      }

      const transportType = this.get('store').peekRecord('transportType', transportTypeId);
      const positiveAuthorityRestrictions = transportType.findPositiveAuthorityRestrictionsByActionTypes(['ADD_TIME_WINDOW', 'ADD_ADVICE', 'RESIZE_ADVICE_WINDOW', 'MOVE_ADVICE_WINDOW']);
      return positiveAuthorityRestrictions.get('length') !== 0 && positiveAuthorityRestrictions.every(ar => {
        return ar.get('authorityRange') !== 'ADVICE_RESTRICTION_BASED';
      });
    },
    canDiscardAssortmentGroupRestrictions: function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_MOVE_WINDOW_OUT_OF_ASSORTMENT_GROUP');
    },
    accountStatus: Ember.computed('enabled', 'accountExpired', function () {
      if (!this.get('enabled')) {
        return 'DELETED';
      }

      if (this.get('temporaryAccount')) {
        return 'TEMPORARY';
      }

      if (this.get('accountExpired')) {
        return 'EXPIRED';
      }

      return 'ACTIVE';
    }),
    temporaryAccountDaysLeft: Ember.computed('dateCreated', function () {
      const activePeriod = this.get('sessionAccount').getParameterValue('TEMPORARY_ACCOUNT_ACTIVE_PERIOD_IN_DAYS');
      const endOfPeriod = moment(this.get('dateCreated')).add(activePeriod, 'days').startOf('day');
      let daysLeft = endOfPeriod.diff(moment().startOf('day'), 'days');

      if (daysLeft < 0) {
        daysLeft = 0;
      }

      return daysLeft;
    }),
    validations: {
      mobilePhoneNumber: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return true;
            }

            return /^\d+$/.test(value);
          },
          message: function (key, value, model) {
            return model.get('intl').t('user.errors.mobilePhoneNumberRegexError');
          }
        }, {
          validation: function (key, value, model) {
            if (!value || model.get('mobilePhoneNumberPrefix') !== '48') {
              return true;
            }

            return value.length === 9;
          },
          message: function (key, value, model) {
            return model.get('intl').t('user.errors.mobilePhoneNumberLengthError');
          }
        }]
      }
    }
  });

  _exports.default = _default;
});