define("apollo/pods/time-window/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator", "ember-data-copyable", "apollo/utils/validators/windowFitsToScheduleValidator"], function (_exports, _emberData, _model, _modelValidator, _emberDataCopyable, _windowFitsToScheduleValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, _emberDataCopyable.default, {
    dateFormatterService: Ember.inject.service('date.date-formatter-service'),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    changeTracker: Object.freeze({
      auto: true,
      enableIsDirty: true
    }),
    ramp: belongsTo('ramp', {
      async: true
    }),
    step: belongsTo('step', {
      async: true
    }),
    transportType: belongsTo('transportType', {
      async: true
    }),
    transport: belongsTo('transport', {
      async: true
    }),
    relatedTransport: belongsTo('transport', {
      async: true,
      inverse: null
    }),
    company: belongsTo('company', {
      async: true
    }),
    userGroup: belongsTo('userGroup', {
      async: true
    }),
    start: attr('date'),
    stop: attr('date'),
    deleted: attr('boolean'),
    isTemplate: attr('boolean'),
    note: attr('string'),
    isSwapped: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    swappedWindowId: attr('number'),
    initialStart: attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    wasMovedAutomatically: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    CARRIER_SECTION: attr('string'),
    ERP_SECTION: attr('string'),
    ALT_SECTION: attr('string'),
    INTERNAL_INDEX_SECTION: attr('string'),
    QUANTITY_SECTION: attr('string'),
    REG_NUM_SECTION: attr('string'),
    INDEX_NAME_SECTION: attr('string'),
    INDEX_DOCUMENT_NUMBER_SECTION: attr('string'),
    TRAILER_REG_NUM_SECTION: attr('string'),
    CONTRACTOR_COUNTRY_SECTION: attr('string'),
    CONTRACTOR_NAME_SECTION: attr('string'),
    CONTRACTOR_GROUP_SECTION: attr('string'),
    STEP_TYPE_ARROW: attr('string'),
    CUSTOM_FIELDS_SECTION: attr('string'),
    DRIVER_NAME: attr('string'),
    STEP_TYPE_SYMBOL: attr('string'),
    state: attr('string'),
    wasDelayedOnMarkedTask: attr('boolean'),
    color: attr('string'),
    additionalCssClassFromTask: attr('string'),
    hasAnyTransportRelation: attr('boolean'),
    hasAnyTransportCombiningRelation: attr('boolean'),
    updateAllFuturePeriodicWindows: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),

    /**
     * Informacja ta nie przychodzi z serwera - jest ustawiana "ręcznie", np. `warehouses/schedule/controller.js`.
     */
    lastUpdated: attr('date'),
    // Własności nie związane z obiektami na serwerze - ustawiane "ręcznie".
    isLockedForEditing: Ember.computed('', function () {
      return false;
    }),
    textColorStyle: Ember.computed('color', function () {
      return this.get('color') ? Ember.String.htmlSafe('color: ' + this.get('color') + ';') : this.get('transport.textColorStyle');
    }).readOnly(),
    backgroundColorStyle: Ember.computed('color', function () {
      return this.get('color') ? Ember.String.htmlSafe('background-color: ' + this.get('color') + ';') : this.get('transport.backgroundColorStyle');
    }).readOnly(),
    grabberColor: Ember.computed('color', function () {
      const DEFAULT_COLOR = '#FFFFFF';

      if (!this.get('color')) {
        return DEFAULT_COLOR;
      }

      const windowColor = this.get('color').substr(1);
      const invertedColor = (Number(`0x1${windowColor}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase();
      return this.isHexValidColor(invertedColor) ? `#${invertedColor}` : DEFAULT_COLOR;
    }).readOnly(),
    STATE_NAMES: Object.freeze({
      dedicated: 'state-dedicated',
      disabled: 'state-disabled',
      template: 'state-template',
      dedicatedTemplate: 'state-dedicated-template',
      transport: 'state-with-transport'
    }),
    validations: {
      stop: {
        custom: [{
          validation: function (key, value, model) {
            if (!value || !model.get('start')) {
              return true;
            }

            return value >= model.get('start');
          },
          message: 'earlierThanStart'
        }, {
          validation: function (key, value, model) {
            if (!value || !model.get('step').get('maxAdviceDate')) {
              return true;
            }

            return value <= model.get('step').get('maxAdviceDate');
          },
          message: 'outsideOfAdviceRange'
        }, {
          validation: function (key, value, model) {
            if (!value || !model.get('ramp') || !model.get('ramp.warehouse.id')) {
              return true;
            }

            const workStartHour = model.get('ramp.warehouse.workStartHour');
            const workEndHour = model.get('ramp.warehouse.workEndHour');
            const validatedValue = moment(value).add(-1, 'seconds');
            const workStartOnMaxDateDay = moment(validatedValue).startOf('day').add(workStartHour, 'hours').toDate();
            const workEndOnMaxDateDay = moment(validatedValue).startOf('day').add(workEndHour, 'hours').toDate();
            return validatedValue >= workStartOnMaxDateDay && validatedValue <= workEndOnMaxDateDay;
          },
          message: function (key, value, self) {
            const warehouseWorkStartHour = self.get('ramp.warehouse.workStartHour');
            const warehouseWorkStartDate = moment(value).startOf('day').add(warehouseWorkStartHour, 'hours').toDate();
            const warehouseWorkEndHour = self.get('ramp.warehouse.workEndHour');
            const warehouseWorkEndDate = moment(value).startOf('day').add(warehouseWorkEndHour, 'hours').toDate();
            return self.get('intl').t('timeWindow.errors.stop.outsideWarehouseWorkHours', {
              workStart: moment(warehouseWorkStartDate).format('HH:mm'),
              workEnd: warehouseWorkEndDate === 24 ? '24:00' : moment(warehouseWorkEndDate).format('HH:mm')
            }).toString();
          }
        }, {
          validation: function (key, value, model) {
            if (!value || !model.get('ramp') || !model.get('ramp.warehouse.id')) {
              return true;
            }

            const windowOffset = model.get('ramp.warehouse.timeWindowOffsetInterval');
            const workStartHour = model.get('ramp.warehouse.workStartHour');
            return _windowFitsToScheduleValidator.default.validate(value, windowOffset, workStartHour);
          },
          message: function (key, value, model) {
            const windowOffset = model.get('ramp.warehouse.timeWindowOffsetInterval');
            const hour = value.getHours();
            const possibleWindowStops = [];
            let validMinutes = 0;

            for (let i = 0; i < 60 / windowOffset; i++) {
              let formattedValidMinutes = validMinutes.toString();

              if (formattedValidMinutes.length === 1) {
                formattedValidMinutes = '0' + formattedValidMinutes;
              }

              possibleWindowStops.pushObject(`${hour}:${formattedValidMinutes}`);
              validMinutes += windowOffset;
            }

            return model.get('intl').t('timeWindow.errors.stop.notConsistentWithWarehouseOffset', {
              possibleWindowStops: possibleWindowStops.join(', ')
            }).toString();
          }
        }]
      },
      start: {
        custom: [{
          validation: function (key, value, model) {
            if (!value || model.get('sessionAccount').hasRole('ROLE_CAN_PLAN_ADVICE_IN_THE_PAST')) {
              return true;
            } // Walidacja powinna obowiązywać tylko w przypadku wprowadzenia zmian w oknie -
            // nie podczas edycji pozostałych właściwości zlecenia.


            const changedAttributes = model.changedAttributes();

            if (!changedAttributes || !model.changedAttributes().start || changedAttributes.start[0] && changedAttributes.start[0].getTime() === changedAttributes.start[1].getTime()) {
              console.log(`Start okna czasowego ${model.get('logString')} nie zmienił się - pomijamy jego walidację.`);
              return true;
            }

            const initialStart = model.get('initialStart')._isAMomentObject ? model.get('initialStart').toDate() : model.get('initialStart');
            const start = value._isAMomentObject ? value.toDate() : value;

            if (!initialStart || initialStart.getTime() === start.getTime()) {
              console.debug('Start of time window hasn\'t changed - validation skipped.');
              return true;
            }

            return start >= new Date();
          },
          message: 'isInPast'
        }, {
          validation: function (key, value, model) {
            if (!value || !model.get('step').get('minAdviceDate')) {
              return true;
            }

            return value >= model.get('step').get('minAdviceDate');
          },
          message: 'outsideOfAdviceRange'
        }, {
          validation: function (key, value, model) {
            if (!value || !model.get('ramp') || !model.get('ramp.warehouse.id')) {
              return true;
            }

            const warehouseWorkStartHour = model.get('ramp.warehouse.workStartHour');
            const warehouseWorkEndHour = model.get('ramp.warehouse.workEndHour');
            const workStartOnAdviceDate = moment(value).startOf('day').add(warehouseWorkStartHour, 'hours').toDate();
            const workEndOnAdviceDate = moment(value).startOf('day').add(warehouseWorkEndHour, 'hours').toDate();
            return value >= workStartOnAdviceDate && value < workEndOnAdviceDate;
          },
          message: function (key, value, self) {
            const warehouseWorkStartHour = self.get('ramp.warehouse.workStartHour');
            const warehouseWorkStartDate = moment(value).startOf('day').add(warehouseWorkStartHour, 'hours').toDate();
            const warehouseWorkEndHour = self.get('ramp.warehouse.workEndHour');
            const warehouseWorkEndDate = moment(value).startOf('day').add(warehouseWorkEndHour, 'hours').toDate();
            return self.get('intl').t('timeWindow.errors.start.outsideWarehouseWorkHours', {
              workStart: moment(warehouseWorkStartDate).format('HH:mm'),
              workEnd: warehouseWorkEndDate === 24 ? '24:00' : moment(warehouseWorkEndDate).format('HH:mm')
            }).toString();
          }
        }, {
          validation: function (key, value, model) {
            if (!value || !model.get('ramp') || !model.get('ramp.warehouse.id')) {
              return true;
            }

            const windowOffset = model.get('ramp.warehouse.timeWindowOffsetInterval');
            const workStartHour = model.get('ramp.warehouse.workStartHour');
            return _windowFitsToScheduleValidator.default.validate(value, windowOffset, workStartHour);
          },
          message: function (key, value, model) {
            const windowOffset = model.get('ramp.warehouse.timeWindowOffsetInterval');
            const hour = value.getHours();
            const possibleWindowStarts = [];
            let validMinutes = 0;

            for (let i = 0; i < 60 / windowOffset; i++) {
              let formattedValidMinutes = validMinutes.toString();

              if (formattedValidMinutes.length === 1) {
                formattedValidMinutes = '0' + formattedValidMinutes;
              }

              possibleWindowStarts.pushObject(`${hour}:${formattedValidMinutes}`);
              validMinutes += windowOffset;
            }

            return model.get('intl').t('timeWindow.errors.start.notConsistentWithWarehouseOffset', {
              possibleWindowStarts: possibleWindowStarts.join(', ')
            }).toString();
          }
        }, {
          validation: function (key, value, model) {
            const steps = model.get('transport.stepsWithWindow').sortBy('orderInTransport');

            if (!steps || steps.length < 2) {
              return true;
            }

            const index = steps.findIndex(step => step.orderInTransport === model.get('step.orderInTransport'));

            if (index > 0) {
              const prevStep = steps.get(index - 1);

              if (value < prevStep.get('timeWindow.stop')) {
                return false;
              }
            }

            return true;
          },
          message: function (key, value, model) {
            return model.get('intl').t('timeWindow.errors.start.startsEarlierThenPreviousWindowStops');
          }
        }]
      }
    },

    /**
     * Sprawdza, czy okno jest powiązane z transportem/stepem -> nie powoduje wykonania zapytania do serwera.
     */
    hasTransport: Ember.computed('step', function () {
      return this.belongsTo('step').id();
    }),
    computedState: Ember.computed('state', 'hasTransport', 'company.id', 'userGroup.id', function () {
      if (this.get('company.id') || this.get('userGroup.id')) {
        return this.get('isTemplate') ? this.get('STATE_NAMES').dedicatedTemplate : this.get('STATE_NAMES').dedicated;
      }

      if (!this.get('hasTransport')) {
        if (this.get('sessionAccount.isPrincipal')) {
          return this.get('isTemplate') ? this.get('STATE_NAMES').template : this.get('STATE_NAMES').disabled;
        } else {
          return this.get('STATE_NAMES').disabled;
        }
      }

      return this.get('state');
    }),
    isDisabled: Ember.computed('computedState', function () {
      return this.get('isBlocked') || this.get('isDedicated');
    }),
    isBlocked: Ember.computed('computedState', function () {
      return this.get('computedState') === this.get('STATE_NAMES').disabled || this.get('computedState') === this.get('STATE_NAMES').template;
    }),
    isDedicated: Ember.computed('computedState', function () {
      return this.get('computedState') === this.get('STATE_NAMES').dedicated || this.get('computedState') === this.get('STATE_NAMES').dedicatedTemplate;
    }),
    durationInMinutes: Ember.computed('start', 'stop', function () {
      return (this.get('stop') - this.get('start')) / (1000 * 60);
    }),

    /**
     * @Deprecated
     * Potrzebne jest jedynie do formatowania dla wartości w URL-u przekierowującego do magazynu.
     * W przyszłości powinniśmy zrezygnować z moment() i być zastąpione.
     */
    startFormattedISO: Ember.computed('start', function () {
      return moment(this.get('start')).format('YYYY-MM-DD');
    }),
    formattedStart: Ember.computed('start', function () {
      const date = this.get('start');
      return this.dateFormatterService.formatShortDate(date);
    }),
    formattedStop: Ember.computed('stop', function () {
      const date = this.get('stop');
      return this.dateFormatterService.formatShortDate(date);
    }),
    formattedTime: Ember.computed('start', 'stop', function () {
      const start = this.get('start');
      const stop = this.get('stop');

      if (this.get('formattedStart') === this.get('formattedStop')) {
        const formattedStartDefault = this.dateFormatterService.formatDefaultDateTime(start);
        const formattedStopShortTime = this.dateFormatterService.formatShortTime(stop);
        return formattedStartDefault + '-' + formattedStopShortTime;
      } else {
        const formattedStartDefault = this.dateFormatterService.formatDefaultDateTime(start);
        const formattedStopDefault = this.dateFormatterService.formatDefaultDateTime(stop);
        return formattedStartDefault + '-' + formattedStopDefault;
      }
    }),
    isLoad: Ember.computed('step.isLoad', function () {
      return this.get('step.isLoad');
    }),
    isUnload: Ember.computed('step.isUnload', function () {
      return this.get('step.isUnload');
    }),
    companyShownInSchedule: Ember.computed('transport.{firstContractor,carrierCompany}', function () {
      return this.get('transport.firstContractor') || this.get('transport.carrierCompany');
    }),
    numberOfErrors: Ember.computed('errors.length', function () {
      return this.get('errors.length');
    }),
    isHexValidColor: function (hexColor) {
      return /^#[0-9A-F]{6}$/i.test(`#${hexColor}`);
    },

    restorePlace() {
      if (!this.get('oldStart')) {
        return console.log(`Okno czasowe ${this.get('id')} nie ma zapisanej wartości w 'oldStart' - nie możemy przywrócić poprzedniego miejsca.`);
      }

      this.setProperties({
        start: this.get('oldStart'),
        stop: this.get('oldStop'),
        ramp: this.get('oldRamp'),
        oldStart: null,
        oldStop: null,
        oldRamp: null
      });
    },

    slotIdentifier: Ember.computed(function () {
      const startDay = moment(this.get('start')).format('YYYY-MM-DD-H-m');
      return `slot-${this.get('ramp.id')}-${startDay}`;
    }),

    lockForEditing() {
      console.debug(`Window ${this.get('logString')} locked for editing..`);
      this.set('isLockedForEditing', true);
    },

    unlockForEditing() {
      console.debug(`Window ${this.get('logString')} unlocked for editing..`);
      this.set('isLockedForEditing', false);
    },

    logString: Ember.computed('id', 'formattedTime', 'ramp.name', function () {
      if (!this.get('id')) {
        return '(niezapisany)';
      }

      return `#${this.get('id')} / ${this.get('formattedTime')}, ${this.get('ramp.name')}`;
    }),
    parts: Ember.computed('start', 'stop', function () {
      const startDay = moment(this.get('start')).startOf('day');
      const stopDay = moment(this.get('stop')).endOf('day');
      const diffInDays = moment(stopDay).diff(startDay, 'days');
      const array = [];

      for (let i = 0; i < diffInDays + 1; i++) {
        array.push(i);
      }

      return array.map(p => {
        return {
          isFirst: p === 0,
          isLast: p === diffInDays,
          shift: p
        };
      });
    })
  });

  _exports.default = _default;
});