define("apollo/utils/objects/Result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Follows the "Result" pattern to handle success and error cases.
   */
  class Result {
    static ok(value) {
      return new Result(value, null);
    }

    static err(error) {
      return new Result(null, error);
    }

    constructor(value, error) {
      this.value = value;
      this.error = error;
    }

    ok() {
      return this.error === null;
    }

    err() {
      return !this.ok();
    }

    valueOr(fallback) {
      return this.ok() ? this.value : fallback;
    }

  }

  _exports.default = Result;
});