define("apollo/utils/functions/date/get-offset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getOffset = getOffset;

  /**
   * @description Gets the timezone offset in minutes for a specific timezone
   * relative to UTC. This function handles millisecond precision by normalizing
   * timestamps to avoid rounding errors.
   *
   * @param {string} ianaTimeZoneName - IANA timezone string (e.g., "America/New_York", "Europe/London", "Europe/Warsaw")
   * @param {Date} [date=new Date()] - Optional date to get the offset for (you may use it when dealing with past or future dates)
   * @returns {number} Timezone offset in minutes (positive values indicate behind UTC, negative values indicate ahead of UTC)
   *
   * @example
   * // Get timezone offset for New York
   * const nyOffset = getOffset('America/New_York')
   * console.log(`New York timezone offset: ${nyOffset} minutes`)
   * /=> "New York timezone offset: 300 minutes" (during EDT)
   *
   * @example
   * // Get timezone offset for Tokyo at a specific date
   * const specificDate = new Date('2023-01-15T12:00:00Z')
   * const tokyoOffset = getOffset('Asia/Tokyo', specificDate)
   * console.log(`Tokyo timezone offset: ${tokyoOffset} minutes`)
   * /=> "Tokyo timezone offset: -540 minutes"
   */
  function getOffset(ianaTimeZoneName, date = new Date()) {
    // Format the date in the target timezone
    const options = {
      timeZone: ianaTimeZoneName,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false // Get formatted parts in the target timezone

    };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const parts = formatter.formatToParts(date); // Extract the parts we need to recreate the date

    const mappedParts = parts.reduce((acc, part) => {
      if (part.type !== 'literal') {
        acc[part.type] = part.value;
      }

      return acc;
    }, {}); // Create a UTC timestamp for the local time in the target timezone
    // This represents what time it is in UTC when it's the specified local time in the target timezone

    const asUTCDate = Date.UTC(mappedParts.year, mappedParts.month - 1, // Month is 0-based in JS Date
    mappedParts.day, mappedParts.hour, mappedParts.minute, mappedParts.second, 0); // Get the timestamp of the input date

    let dateTimestamp = +date;
    const utcDateTimestamp = +asUTCDate;
    const msReminder = dateTimestamp % 1000; // Adjust the timestamp to remove milliseconds to mitigate any round errors

    dateTimestamp -= msReminder >= 0 ? msReminder : 1000 + msReminder;
    return (dateTimestamp - utcDateTimestamp) / 60000;
  }
});