define("apollo/helpers/my-format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage examples:
   *
   * {{my-format-date model.dateCreated 'full'}}
   * {{my-format-date model.lastUpdated 'short' fallback="Oops!"}}
   * {{my-format-date model.arrivalDate 'medium' locale="fr-FR"}}
   * {{my-format-date model.arrivalDate 'fullDateTime' timeZone='America/Chihuahua'}}
   * {{my-format-date model.dateCreated 'custom' hour="numeric" minute="numeric" hourCycle="h24"}}
   */
  var _default = Ember.Helper.extend({
    dateFormatterService: Ember.inject.service('date.date-formatter-service'),

    /**
     * @returns string
     */
    compute(params, hash) {
      const [date, style = 'medium'] = params;

      if (!date) {
        return '';
      }

      const {
        locale,
        timeZone,
        ...formatOptions
      } = hash;
      const options = {};

      if (locale) {
        options.locale = locale;
      }

      if (timeZone) {
        options.timeZone = timeZone;
      }

      let result;

      switch (style) {
        case 'full':
          result = this.dateFormatterService.formatFullDate(date, options);
          break;

        case 'short':
          result = this.dateFormatterService.formatShortDate(date, options);
          break;

        case 'fullTime':
          result = this.dateFormatterService.formatFullTime(date, options);
          break;

        case 'shortTime':
          result = this.dateFormatterService.formatShortTime(date, options);
          break;

        case 'fullDateTime':
          result = this.dateFormatterService.formatFullDateTime(date, options);
          break;

        case 'defaultDateTime':
          result = this.dateFormatterService.formatDefaultDateTime(date, options);
          break;

        case 'custom':
          result = this.dateFormatterService.formatCustom(date, formatOptions, options);
          break;

        case 'medium':
        default:
          result = this.dateFormatterService.formatMediumDate(date, options);
      }

      return result;
    }

  });

  _exports.default = _default;
});