define("apollo/helpers/get-timezone-abbreviation", ["exports", "apollo/utils/functions/date/is-valid-iana-zone", "apollo/utils/functions/date/get-timezone-abbreviation"], function (_exports, _isValidIanaZone, _getTimezoneAbbreviation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params, hash) {
      const [ianaTimezone] = params;

      if (!(0, _isValidIanaZone.isValidIanaZone)(ianaTimezone)) {
        return 'Invalid time zone';
      }

      return (0, _getTimezoneAbbreviation.getTimezoneAbbreviation)(ianaTimezone);
    }

  });

  _exports.default = _default;
});