define("apollo/utils/date-formatter", ["exports", "apollo/utils/objects/Result"], function (_exports, _Result) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * DateFormatter - A utility wrapper for Intl.DateTimeFormat
   * Provides standardized date formatting functions for the application
   * Uses the Result pattern to handle success and error cases
   */
  class DateFormatter {
    /**
     * @param {string} locale - The locale to use for formatting (e.g., 'en-US', 'fr-FR')
     * @param {string} timeZone - The timezone to use for formatting (e.g., 'UTC', 'America/New_York')
     */
    constructor(locale = 'en-US', timeZone = 'UTC') {
      this.locale = locale;
      this.timeZone = timeZone;
    }
    /**
     * Safely format a date with error handling using the Result pattern
     * @param {Date|number|string} date - The date to format
     * @param {Object} options - Intl.DateTimeFormat options
     * @returns {Result<string>} A result containing the formatted date or an error
     * @private
     */


    _formatWithResult(date, options) {
      try {
        const dateObj = new Date(date);

        if (isNaN(dateObj.getTime())) {
          return _Result.default.err('Invalid Date');
        }

        const formatted = new Intl.DateTimeFormat(this.locale, options).format(dateObj);
        return _Result.default.ok(formatted);
      } catch (err) {
        console.warn('Date formatting error: ', err);
        return _Result.default.err('Date Formatting Error');
      }
    }
    /**
     * Format a date to full date and time (e.g., "January 1, 2023, 3:00:00 PM")
     * @param {Date|number|string} date - The date to format
     * @returns {Result<string>} Formatted date and time string
     */


    formatFullDateTime(date) {
      const options = {
        timeZone: this.timeZone,
        dateStyle: 'full',
        timeStyle: 'long'
      };
      return this._formatWithResult(date, options);
    }
    /**
     * Format a date to full date only (e.g., "Monday, January 1, 2023")
     * @param {Date|number|string} date - The date to format
     * @returns {Result<string>} Formatted date string
     */


    formatFullDate(date) {
      const options = {
        timeZone: this.timeZone,
        dateStyle: 'full'
      };
      return this._formatWithResult(date, options);
    }
    /**
     * Format a date to medium date only (e.g., "Jan 1, 2023")
     * @param {Date|number|string} date - The date to format
     * @returns {Result<string>} Formatted date string
     */


    formatMediumDate(date) {
      const options = {
        timeZone: this.timeZone,
        dateStyle: 'medium'
      };
      return this._formatWithResult(date, options);
    }
    /**
     * Format a date to short date only (e.g., "01/01/2023")
     * @param {Date|number|string} date - The date to format
     * @returns {Result<string>} Formatted date string
     */


    formatShortDate(date) {
      const options = {
        timeZone: this.timeZone,
        year: 'numeric',
        day: '2-digit',
        month: '2-digit'
      };
      return this._formatWithResult(date, options);
    }
    /**
     * Format a date to a default date time format (e.g., "18.03.2023, 15:15 CET")
     * @param {Date|number|string} date - The date to format
     * @returns {Result<string>} Formatted date string
     */


    formatDefaultDateTime(date) {
      const options = {
        timeZone: this.timeZone,
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short'
      };
      return this._formatWithResult(date, options);
    }
    /**
     * Format a date to full time only (e.g., "3:00:00 PM")
     * @param {Date|number|string} date - The date to format
     * @returns {Result<string>} Formatted time string
     */


    formatFullTime(date) {
      const options = {
        timeZone: this.timeZone,
        timeStyle: 'long'
      };
      return this._formatWithResult(date, options);
    }
    /**
     * Format a date to short time only (e.g., "3:00 PM")
     * @param {Date|number|string} date - The date to format
     * @returns {Result} Formatted time string
     */


    formatShortTime(date) {
      const options = {
        timeZone: this.timeZone,
        timeStyle: 'short'
      };
      return this._formatWithResult(date, options);
    }
    /**
     * Custom format with specific options
     * @param {Date|number|string} date - The date to format
     * @param {Object} options - Intl.DateTimeFormat options
     * @returns {Result<string>} Formatted date string
     */


    formatCustom(date, options = {}) {
      const defaultOptions = {
        timeZone: this.timeZone
      };
      const mergedOptions = { ...defaultOptions,
        ...options
      };
      return this._formatWithResult(date, mergedOptions);
    }

  }

  _exports.default = DateFormatter;
});