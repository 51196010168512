define("apollo/utils/functions/generate-time-objects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateTimeObjects = generateTimeObjects;

  /**
   * @description Creates time objects at intervals between start and end hours.
   * Used in the schedule component to generate the time grid for displaying time-windows.
   *
   * @param {Object} options - Configuration options
   * @param {Number} options.scheduleStartHour - Schedule starting hour (0-23)
   * @param {Number} options.scheduleEndHour - Schedule ending hour (0-23)
   * @param {Number} options.intervalMinutes - Interval in minutes between time objects (e.g., 15, 30, 60)
   * @param {Number} [options.hourOffset=0] - Optional offset in hours to apply (can be negative) for timezone adjustments
   * @return {Array} Array of time objects with hour and minute properties, each with a formattedTime computed property
   *
   * @example
   * // Generate time slots from 9am to 5pm at 30-minute intervals
   * const timeSlots = generateTimeObjects({
   *   scheduleStartHour: 9,
   *   scheduleEndHour: 17,
   *   intervalMinutes: 30
   * });
   *
   * @example
   * // With timezone offset (e.g., -2 hours adjustment)
   * const timeSlotsWithOffset = generateTimeObjects({
   *   scheduleStartHour: 9,
   *   scheduleEndHour: 17,
   *   intervalMinutes: 15,
   *   minutesOffset: -120 // -2 hours in minutes
   * });
   */
  function generateTimeObjects({
    scheduleStartHour,
    scheduleEndHour,
    intervalMinutes,
    minutesOffset = 0
  }) {
    // Create the time object definition
    const TimeObject = Ember.Object.extend({
      hour: null,
      minutes: null,
      dayOffset: 0,
      // Track day change: 0 = same day, -1 = previous day, 1 = next day
      // Add a formatted time property for convenience
      formattedTime: Ember.computed('hour', 'minutes', function () {
        return `${this.hour}:${this.minutes}`;
      })
    }); // Create start and end dates using native Date API

    const createTimeWithHour = hours => {
      const date = new Date();
      const offsetHours = Math.floor(minutesOffset / 60);
      const offsetMinutesReminder = minutesOffset % 60;
      date.setHours(hours + offsetHours, offsetMinutesReminder, 0, 0); // Set hour with minute-precision offset

      return date;
    };

    const start = createTimeWithHour(scheduleStartHour);
    const end = createTimeWithHour(scheduleEndHour); // Calculate total minutes and steps

    const totalMinutes = (end - start) / (60 * 1000); // Convert ms to minutes

    const numberOfSteps = Math.floor(totalMinutes / intervalMinutes);
    const referenceDate = new Date();
    referenceDate.setHours(0, 0, 0, 0);
    return Array.from({
      length: numberOfSteps
    }, (_, i) => {
      // Clone the start date and add the appropriate number of minutes
      const currentTime = new Date(start.getTime() + i * intervalMinutes * 60 * 1000); // Format hours and minutes with padStart for consistent 2-digit format

      const hour = String(currentTime.getHours()).padStart(2, '0');
      const minutes = String(currentTime.getMinutes()).padStart(2, '0');
      const daysDifference = Math.floor((currentTime - referenceDate) / (24 * 60 * 60 * 1000));
      return TimeObject.create({
        hour,
        minutes,
        dayOffset: daysDifference
      });
    });
  }
});