define("apollo/pods/document/model", ["exports", "ember-data", "apollo/pods/section/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_modelValidator.default, {
    sessionAccount: Ember.inject.service('session-account'),
    documentType: _emberData.default.belongsTo('documentType', {
      async: true
    }),
    currency: _emberData.default.belongsTo('currency', {
      async: true
    }),
    partnership: _emberData.default.belongsTo('partnership', {
      async: true
    }),
    truck: _emberData.default.belongsTo('truck', {
      async: true
    }),
    number: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    scopeOfInsurance: _emberData.default.attr('string'),
    expirationDateFrom: _emberData.default.attr('date'),
    expirationDate: _emberData.default.attr('date'),
    files: _emberData.default.hasMany('file', {
      async: true
    }),
    validations: {
      number: {
        custom: {
          validation: function (key, value, model) {
            if (!model.get('documentType.showNumber')) {
              return true;
            }

            return !!value;
          },
          message: 'blank'
        }
      },
      expirationDate: {
        custom: {
          validation: function (key, value, model) {
            if (!model.get('documentType.showExpirationDateTo')) {
              return true;
            }

            return value && value >= moment().startOf('day').toDate();
          },
          message: 'isInPast'
        }
      },
      expirationDateFrom: {
        custom: {
          validation: function (key, value, model) {
            if (!model.get('documentType.showExpirationDateFrom')) {
              return true;
            }

            if (!model.get('isInsurance') || !model.get('expirationDate')) {
              return true;
            }

            return value && value < model.get('expirationDate');
          },
          message: 'afterExpirationDate'
        }
      },
      currency: {
        custom: {
          validation: function (key, value, model) {
            return value && value.get('id') || !model.get('isInsurance');
          },
          message: 'blankSelect'
        }
      },
      amount: {
        custom: {
          validation: function (key, value, model) {
            return value || !model.get('isInsurance');
          },
          message: 'blank'
        }
      },
      scopeOfInsurance: {
        custom: {
          validation: function (key, value, model) {
            return value || !model.get('isInsurance');
          },
          message: 'blank'
        }
      }
    },
    typeName: Ember.computed('documentType.name', function () {
      return this.get('documentType.name');
    }),
    groupName: Ember.computed('documentType.groupName', function () {
      return this.get('documentType.groupName');
    }),
    isLicence: Ember.computed('groupName', function () {
      return this.get('groupName') === 'LICENCE';
    }),
    isInsurance: Ember.computed('groupName', function () {
      return this.get('groupName') === 'INSURANCE';
    }),
    isDomestic: Ember.computed('documentType.id', function () {
      return this.get('documentType.ibanRestriction') === 'PL';
    }),
    howManyDaysBeforeDocumentExpirationDateCarrierShouldBeBlocked: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('HOW_MANY_DAYS_BEFORE_DOCUMENT_EXPIRATION_DATE_CARRIER_SHOULD_BE_BLOCKED') || 0;
    }),
    calculatedExpirationDate: Ember.computed('expirationDate', function () {
      const shift = this.get('howManyDaysBeforeDocumentExpirationDateCarrierShouldBeBlocked');
      return moment(this.get('expirationDate')).add(-shift, 'days').format('DD.MM.YYYY');
    }),

    checkIfDocumentIsExpiring(setting) {
      const now = moment();
      const redWarningDate = moment(this.get('calculatedExpirationDate'), 'DD.MM.YYYY');
      const yellowWarningInterval = this.get('sessionAccount').getSettingValue(setting);
      const yellowWarningDate = moment(redWarningDate).add(-yellowWarningInterval, 'days');

      if (now.isAfter(redWarningDate)) {
        return 'red-warning';
      } else if (now.isAfter(yellowWarningDate)) {
        return 'yellow-warning';
      } else {
        return '';
      }
    },

    certificateWarningClass: Ember.computed('calculatedExpirationDate', function () {
      return this.checkIfDocumentIsExpiring('HOW_MANY_DAYS_BEFORE_CERTIFICATE_EXPIRATION_SHOULD_MAIL_BE_SENT');
    }),
    mappingWarningClass: Ember.computed('calculatedExpirationDate', function () {
      return this.checkIfDocumentIsExpiring('HOW_MANY_DAYS_BEFORE_MAPPING_EXPIRATION_SHOULD_MAIL_BE_SENT');
    }),
    blocksAdviceWhenInvalid: Ember.computed('documentType.invalidDocumentBlocksAdvice', function () {
      return this.get('documentType.invalidDocumentBlocksAdvice');
    })
  });

  _exports.default = _default;
});