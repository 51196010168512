define("apollo/pods/components/planning/planned-panel/transport-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    actionProgressToast: Ember.inject.service('action-progress-service'),
    intl: Ember.inject.service(),
    currentDeliveries: Ember.computed('transport.deliveries', function () {
      return this.get('transport.deliveries');
    }),
    totalWeight: Ember.computed('currentDeliveries.@each.grossWeight', function () {
      const deliveries = this.get('currentDeliveries');
      let totalWeight = 0;
      deliveries.forEach(delivery => {
        totalWeight += delivery.get('grossWeight');
      });
      return totalWeight;
    }),
    actions: {
      async releaseTransport() {
        const self = this;
        self.actionProgressToast.showLoadingToast(this.get('intl').t('notificationToast.deliveryTransport.CREATE.loading') + this.get('transport').get('internalIndex'));

        try {
          const response = await this.apolloApiService.callApolloApi(this.apolloApiService.APOLLO_API.PLANNING.RELEASE_TRANSPORT, null, {
            body: JSON.stringify({
              transportId: this.get('transport.id')
            })
          });

          if (response.ok) {
            self.actionProgressToast.showSuccessToast(this.get('intl').t('notificationToast.deliveryTransport.CREATE.success') + this.get('transport').get('internalIndex'));
            this.get('transport').reload();
          } else {
            const err = await response.json();
            self.actionProgressToast.showErrorToast(this.get('intl').t('notificationToast.deliveryTransport.CREATE.error') + this.get('transport').get('internalIndex'), err.errors);
          }
        } catch (e) {
          self.actionProgressToast.showErrorToast(this.get('intl').t('notificationToast.deliveryTransport.CREATE.error') + this.get('transport').get('internalIndex'));
          console.log(e);
        }
      },

      async deleteTransport(transport) {
        const path = this.apolloApiService.APOLLO_API.PLANNING.DELETE_BASIC_TRANSPORT;
        const response = await this.apolloApiService.callApolloApi(path, {
          transportId: transport.get('id')
        });

        if (!response.ok) {
          const error = await response.json();
          console.error('Błąd podczas usuwania wstępnego transportu. ' + error.errors);
        }

        transport.set('deleted', true);
        transport.unloadRecord();
        this.set('action', 'refreshModel');
        this.sendAction('action');
      }

    }
  });

  _exports.default = _default;
});